import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DraycirSpinner from "../../../../../components/spinners/DraycirSpinner";
import {
  ThreeDSecureAcsRedirectUrlParamName,
  ThreeDSecureCRegDataParamName,
  ThreeDSecureSessionDataParamName,
} from "../opayoPiCard";
import { buildOpayoPi3dSecureRedirectForm } from "../opayoPiScriptUtils";

/*
 * Initiates the redirect to a 3d Secure challenge page in an iframe
 * This component is show within a popup dialog
 */
const OpayoPi3dSecureFramePage = (): ReactElement => {
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const acsRedirectUrl = params.get(ThreeDSecureAcsRedirectUrlParamName);
    const cRegData = params.get(ThreeDSecureCRegDataParamName);
    const threeDSSessionData = params.get(ThreeDSecureSessionDataParamName);

    // Once we have the data we need, we can submit the form to redirect the user to the 3d secure challenge page
    if (acsRedirectUrl && cRegData && threeDSSessionData) {
      const form = buildOpayoPi3dSecureRedirectForm(document, acsRedirectUrl, cRegData, threeDSSessionData);
      form.submit();
    }
  }, [search]);

  return (
    <div className='d-flex justify-content-center '>
      <DraycirSpinner />
    </div>
  );
};

export default OpayoPi3dSecureFramePage;
