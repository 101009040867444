import { ReactElement, ReactNode } from "react";
import AdminPortalRoutes from "../../common/adminPortalRoutes";

interface AdminPortalLinkProps {
  path?: string;
  children: ReactNode;
}

/*
 * Link to the admin portal
 */
const AdminPortalLink = ({ children, path }: AdminPortalLinkProps): ReactElement => {
  const url = path ? AdminPortalRoutes.buildUrl(path) : AdminPortalRoutes.getUrl();
  return (
    <a href={url} target='_blank' rel='noreferrer'>
      {children}
    </a>
  );
};

AdminPortalLink.defaultProps = {
  path: undefined,
};

export default AdminPortalLink;
