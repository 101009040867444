/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import { format } from "date-fns";
// import { zonedTimeToUtc } from "date-fns-tz";
import { enUS } from "date-fns/locale";
import CurrencyItem from "../../components/misc/CurrencyItem";
import PaymentButton from "../Payments/PaymentButton";
import { getCurrentUserLocale, initialiseDateFormatter } from "../../utils/dateFormatter";

interface DateTester {
  simpleDateNoTime: string;
  utcDateTime: string;
}

const getDateTester = (): DateTester => {
  const test = {
    simpleDateNoTime: "2022-05-14T23:00:00",
    utcDateTime: "2022-05-14T23:00:00Z",
  };
  return test as unknown as DateTester;
};

export const getValidDate = (date: Date | undefined): Date | undefined => {
  if (date !== null && date !== undefined && typeof date === "object" && "toISOString" in date) {
    return date;
  }
  if (date && date !== null) {
    try {
      return new Date(date);
    } catch (error) {
      // Do nothing
    }
  }
  return undefined;
};

/*
 * Various date times coming in from API
 *
 * UTC Date/time which needs to be converted to local time
 *
 * Fixed date which needs to be shown as is
 *
 */

initialiseDateFormatter();

const TestPage = (): ReactElement => {
  const test = getDateTester();

  // initialiseDateFormatter();
  const locale = getCurrentUserLocale();
  // zonedTimeToUtc(test.simpleDateNoTime, )

  const simpleDate = new Date(test.simpleDateNoTime);
  const utcDate = new Date(test.utcDateTime);
  return (
    <Container>
      <div className='text-center'>
        <p>{test.simpleDateNoTime}</p>
        <p>{format(utcDate, "PP pp", { locale })}</p>
        <p>{utcDate.toISOString()}</p>
        <p>{utcDate.toUTCString()}</p>
        <p> </p>
        <p>{test.simpleDateNoTime}</p>
        <p>{format(utcDate, "PP pp", { locale })}</p>
        <p>{utcDate.toISOString()}</p>
        <p>{utcDate.toUTCString()}</p>
      </div>
    </Container>
  );
};

export default TestPage;
