import React, { ReactElement, Suspense, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { getPaymentRequest } from "../../services/paymentRequests/paymentRequests.service";
import LoadingPage from "../LoadingPage";
import { PaymentRequestSummary } from "../../models/paymentRequests/paymentRequestSummary";
import { PaymentRequestStatus } from "../../models/paymentRequests/paymentRequestStatus";
import { RequestTypes } from "../../models/paymentRequests/requestTypes";
import UnsupportedPaymentType from "./SupportedPaymentType";
import PaymentRequestProvider from "../../context/paymentRequest/PaymentRequestProvider";
import usePaymentRequest from "../../context/paymentRequest/PaymentRequestContext";
import PaymentRequestReservePendingPage from "./PaymentRequestReservePendingPage";
import PaymentRequestCurrentlyProcessingPage from "./PaymentRequestCurrentlyProcessingPage";

const Completed = React.lazy(() => import("./Completed"));
const RequestNotValidPage = React.lazy(() => import("./RequestNotValidPage"));
const InvoicePaymentRequestPage = React.lazy(() => import("./Invoice/InvoicePaymentRequestPage"));
const AccountPaymentRequestPage = React.lazy(() => import("./Account/AccountPaymentRequestPage"));

/*
 * Displays the payment request page depending on the status or type of request
 */
const PaymentRequestOptions = (): ReactElement => {
  const paymentRequest = usePaymentRequest();

  // Show the completed page if payment request has been completed
  if (paymentRequest.requestStatus === PaymentRequestStatus.Completed) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <Completed />
      </Suspense>
    );
  }

  // Show the reserve pending page if the status has been set to pending (i.e. a reserved payment has been made)
  if (paymentRequest.requestStatus === PaymentRequestStatus.Pending) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <PaymentRequestReservePendingPage />
      </Suspense>
    );
  }

  // Show the payments processing page if the there are any processing or pending payment transactions
  if (paymentRequest.hasProcessingOrPendingPaymentTransactions === true) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <PaymentRequestCurrentlyProcessingPage />
      </Suspense>
    );
  }

  // Now we know the payment request is valid and we can display the relevent payment request page

  if (paymentRequest.requestType === RequestTypes.Transaction) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <InvoicePaymentRequestPage />
      </Suspense>
    );
  }

  if (paymentRequest.requestType === RequestTypes.Account) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <AccountPaymentRequestPage />;
      </Suspense>
    );
  }
  return <UnsupportedPaymentType />;
};

/*
 * Displays and handles the payment request
 */
const PaymentRequestPage = (): ReactElement => {
  const handleError = useErrorHandler();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequestSummary>();

  // Reads in the payment request and builds the transaction cart
  useEffect(() => {
    const readPaymentRequest = async () => {
      const response = await getPaymentRequest(handleError, true);
      if (response) {
        setPaymentRequest(response);
      }
    };
    readPaymentRequest();
  }, [handleError]);

  if (!paymentRequest) return <LoadingPage />;

  if (paymentRequest && (!paymentRequest.validFromOk || !paymentRequest.validUntilOk)) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <PaymentRequestProvider paymentRequestSummary={paymentRequest}>
          <RequestNotValidPage />
        </PaymentRequestProvider>
      </Suspense>
    );
  }

  return (
    <PaymentRequestProvider paymentRequestSummary={paymentRequest}>
      <PaymentRequestOptions />
    </PaymentRequestProvider>
  );
};

export default PaymentRequestPage;
