import { useEffect } from "react";
import { loadOpayoPiScript, removeOpayoPiScript } from "../opayoPiScriptUtils";

/*
 * Handles the loading (and unloading on an unmount) of the Opayo Pi script we receive back from an intent to pay.
 */
const useOpayoPiScriptLoader = (
  merchantSessionKey: string | null | undefined,
  liveServer: boolean | undefined,
  formReadyCallback: () => void
) => {
  // Load in the script once we have a merchantSessionKey and liveServer flag
  useEffect(() => {
    if (merchantSessionKey && liveServer !== undefined) {
      loadOpayoPiScript(merchantSessionKey, !liveServer, (wnd, key) => {
        const checkout = wnd.sagepayCheckout({ merchantSessionKey: key });
        checkout.form();
        formReadyCallback();
      });
      return () => {
        removeOpayoPiScript();
      };
    }
    // No script supplied so do nothing on an unmount
    return () => {};
    // We only want to run this when we have a valid merchantSessionKey, so we don't want to include the callback in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantSessionKey, liveServer]);
};

export default useOpayoPiScriptLoader;
