import PaymentProvidersApi from "../../common/api/payThemPayerApi/paymentProvidersApi";
import PaymentRequestsApi from "../../common/api/payThemPayerApi/paymentRequestsApi";
import { PaymentProviderConfigurationDetails } from "../../models/paymentProviders/paymentProviderConfigurationDetails";
import { PaymentRequestSummary } from "../../models/paymentRequests/paymentRequestSummary";
import { PaymentCalculationResult } from "../../models/paymentDetails/paymentCalculationResult";

/*
 * Returns the payment request we're dealing with
 */
export const getPaymentRequest = async (
  errorHandler?: Function | undefined,
  includeTransactions: boolean = false
): Promise<PaymentRequestSummary | undefined> => {
  try {
    const api = new PaymentRequestsApi();
    const result = await api.getPaymentRequest(includeTransactions);
    return result;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return undefined;
};

/*
 *  Returns the payment provider configurations associated with this payment request
 */
export const getPaymentProviderConfigurations = async (
  amountToPay: number,
  errorHandler?: Function | undefined
): Promise<PaymentProviderConfigurationDetails[] | undefined> => {
  try {
    const api = new PaymentProvidersApi();
    const result = await api.getPaymentProviderConfigurations(amountToPay);
    return result;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return undefined;
};

/*
 * Calculates the payment results for the payment request
 */
export const calculatePaymentResults = async (
  errorHandler?: Function | undefined
): Promise<PaymentCalculationResult[] | undefined> => {
  try {
    const api = new PaymentRequestsApi();
    const result = await api.calculatePayment();
    return result;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    }
  }
  return undefined;
};

export default getPaymentRequest;
