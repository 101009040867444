import BasePayThemPayerApi from "./basePayThemPayerApi";
import { PaymentRequestSummary } from "../../../models/paymentRequests/paymentRequestSummary";
import { PaymentCalculationResult } from "../../../models/paymentDetails/paymentCalculationResult";

class PaymentRequestsApi extends BasePayThemPayerApi {
  private endPoint = "payment-request";

  /*
   * Returns the payment request
   */
  public async getPaymentRequest(includeTransactions = false): Promise<PaymentRequestSummary> {
    const params = new URLSearchParams();
    if (includeTransactions) params.append("expand", "transactions");
    const result = await this.get<PaymentRequestSummary>(this.endPoint, params);
    return result;
  }

  /*
   * Calculates the payment results for the payment request
   */
  public async calculatePayment(): Promise<PaymentCalculationResult[]> {
    const url = `${this.endPoint}/calculate-payment`;
    const result = await this.get<PaymentCalculationResult[]>(url);
    return result;
  }
}

export default PaymentRequestsApi;
