import React, { ReactNode } from "react";
import { Card } from "react-bootstrap";

interface Props {
  children: ReactNode;
  className?: string;
  width?: string;
  height?: string;
}

const DCCard: React.FC<Props> = ({ children, className, width, height }) => (
  <Card className={className} style={{ width, height }}>
    <Card.Body>{children}</Card.Body>
  </Card>
);

DCCard.defaultProps = {
  className: undefined,
  width: "18rem",
  height: undefined,
};

export default DCCard;
