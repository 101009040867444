import {
  CardEventMessageType,
  CardEventMessage,
  CardEventMessageNameValue,
  CardSubmitResultType,
  CardScriptLoaded,
  CardScrollHeightChanged,
  CardRedirectToProcessing,
} from "../cardEvent";
import { SubmitResult } from "../opayoPiCard";

/*
 * This class is used to send events from the card iframe to the card parent (main PayThem window)
 */
class CardIFrameEventSender {
  private sendMessageToParent = <T>(type: CardEventMessageType, data?: T) => {
    const message: CardEventMessage<T> = {
      name: CardEventMessageNameValue,
      type,
      data,
    };
    // We know that both the parent and the iframe are on the same domain so we can use window.parent
    window.parent.postMessage(message, window.location.origin);
  };

  public sendSubmitCardResult = (submitRequest: SubmitResult) => {
    this.sendMessageToParent(CardSubmitResultType, submitRequest);
  };

  // Called when we have loaded the Opayo script
  public sendOpayoScriptLoaded = () => {
    this.sendMessageToParent(CardScriptLoaded);
  };

  /// Called when the scroll height of the card changes
  public sendScrollHeightChanged = (scrollHeight: number) => {
    this.sendMessageToParent(CardScrollHeightChanged, scrollHeight);
  };

  // Called to direct the parent to redirect to the processing page
  public sendRedirectToProcessing = () => {
    this.sendMessageToParent(CardRedirectToProcessing);
  };
}

export default CardIFrameEventSender;
