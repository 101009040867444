import { createContext, useContext } from "react";
import { PaymentRequestSummary } from "../../models/paymentRequests/paymentRequestSummary";

export type PaymentRequestContextType = PaymentRequestSummary;

export const PaymentRequestContext = createContext<PaymentRequestContextType>({} as PaymentRequestContextType);

/*
 * Context for supplying the payment request
 */
const usePaymentRequest = (): PaymentRequestContextType => {
  const context = useContext(PaymentRequestContext);
  if (context === undefined) {
    throw new Error("usePaymentRequest must be used within a CustomerDetailsProvider");
  }
  return context;
};

export default usePaymentRequest;
