/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, ReactNode, useEffect, useState } from "react";
import CompanyHeaderInfo from "../../models/companySettings/CompanyHeaderInfo";
import getCompanyHeaderInfo from "../../services/companySettings/companySettings.service";
import { useSession } from "../session/SessionContext";
import { CompanyInfoContext } from "./CompanyInfoContext";

interface ProviderProps {
  children: ReactNode;
}

/*
 * Provides the company info header
 */
const CompanyInfoProvider = ({ children }: ProviderProps): ReactElement => {
  const [companyInfo, setCompanyInfo] = useState<CompanyHeaderInfo>();
  const { sessionToken } = useSession();

  useEffect(() => {
    const loadCompanyInfo = async () => {
      const result = await getCompanyHeaderInfo();
      if (result) {
        setCompanyInfo(result);
      }
    };
    if (!companyInfo && sessionToken) {
      loadCompanyInfo();
    }
  }, [companyInfo, sessionToken]);

  return <CompanyInfoContext.Provider value={companyInfo}>{children}</CompanyInfoContext.Provider>;
};

export default CompanyInfoProvider;
