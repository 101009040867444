import { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animation404 from "../../assets/data/anim/404.json";

const Error404Page = (): ReactElement => {
  const { t } = useTranslation();
  const actionMessage = t("errors.httpError404NotFound.actionMessage");

  return (
    <Container fluid style={{ paddingTop: "0", marginTop: "0" }}>
      <Row>
        <Col className='p-0'>
          <div className='d-flex flex-column align-items-center' style={{ paddingTop: "0", marginTop: "0" }}>
            <div style={{ width: "65%", marginTop: "0px", marginBottom: "0px", zIndex: "0" }}>
              <Lottie animationData={animation404} title='404' />
            </div>
            <Link className='go-home' to='/' style={{ zIndex: "100" }}>
              {actionMessage}
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Error404Page;
