import { ReactElement, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CardIdentifierResult,
  CardIdentifierErrorCodeParamName,
  CardIdentifierErrorMessageParamName,
  CardIdentifierHttpCodeParamName,
  CardIdentifierParamName,
  IsLiveParamName,
  MerchantSessionKeyParamName,
  IFrameType,
} from "../opayoPiCard";
import CardFrameEventListener from "./CardFrameEventListener";
import CardIFrameEventSender from "./CardIFrameEventSender";
import { getBrowserInfo } from "../../../../../utils/BrowserInfo/browserInfo";
import DraycirSpinner from "../../../../../components/spinners/DraycirSpinner";
import useOpayoPiScriptLoader from "./useOpayoPiScriptLoader";
import "./OpayoPiCardFramePage.scss";

// Builds the card identifier result from the URL parameters
const buildCardIdentifierResultFromParameters = (params: URLSearchParams): CardIdentifierResult | undefined => {
  const cardIdentifier = params.get(CardIdentifierParamName);
  const cardIdentifierHttpCode = params.get(CardIdentifierHttpCodeParamName);
  const cardIdentifierErrorCode = params.get(CardIdentifierErrorCodeParamName);
  const cardIdentifierErrorMessage = params.get(CardIdentifierErrorMessageParamName);

  if (cardIdentifier || cardIdentifierHttpCode || cardIdentifierErrorCode || cardIdentifierErrorMessage) {
    const cardInfo: CardIdentifierResult = {
      cardIdentifier: params.get(CardIdentifierParamName),
      cardIdentifierHttpCode: params.get(CardIdentifierHttpCodeParamName),
      cardIdentifierErrorCode: params.get(CardIdentifierErrorCodeParamName),
      cardIdentifierErrorMessage: params.get(CardIdentifierErrorMessageParamName),
    };
    return cardInfo;
  }
  return undefined;
};

// Creates the event listener for the card frame (i.e. listens for the user pressing the payment button)
const createCardFrameEventListener = (onSetIFrameType: (iFrameType: IFrameType) => void): CardFrameEventListener =>
  new CardFrameEventListener({
    onPaymentButtonSumbit: () => {
      // Simulate a click on the hidden button
      const hiddenButton = document.getElementById("opayo-sumbit-button");
      if (hiddenButton) {
        hiddenButton.click();
      }
    },
    onSetIFrameType: (iFrameType: IFrameType) => {
      // Inform our parent that we've submitted the card details and have received a result from Opayo
      onSetIFrameType(iFrameType);
    },
  });

/*
 * Collects the card details from the user and submits them to Opayo.
 * We embed the opayo card frame in our iframe using the script loader, when we find the merchant session key in the URL.
 * When the payment is submitted, Opayo will redirect the user back to the site with the card identifer in the URL.
 * We then post a message back to the parent window with the card identifier or error details.
 * We've done all of this within our own iframe so that we can control the styling of the card frame and not have a
 * redirect performed on the main site
 */
const OpayoPiCardFramePage = (): ReactElement => {
  const [merchantSessionKey, setMerchantSessionKey] = useState<string | null | undefined>();
  const [liveServer, setLiveServer] = useState<boolean>();
  const [iFrameType, setIFrameType] = useState<IFrameType>(IFrameType.CardDetails);
  const [cardIdentifierResult, setCardIdentifierResult] = useState<CardIdentifierResult>();
  const { search } = useLocation();
  const formRef = useRef<HTMLFormElement>(null);

  // Loads in the Opayo script when we have a merchant session key
  useOpayoPiScriptLoader(merchantSessionKey, liveServer, () => new CardIFrameEventSender().sendOpayoScriptLoaded());

  // Handles the message coming from the parent window to submit the card details
  useEffect(() => {
    const eventListener = createCardFrameEventListener((type) => setIFrameType(type));
    return () => {
      eventListener.cleanup();
    };
  }, []);

  // Extracts the parameters from the URL when is changes
  useEffect(() => {
    const params = new URLSearchParams(search);
    setMerchantSessionKey(params.get(MerchantSessionKeyParamName));
    if (params.has(IsLiveParamName)) {
      setLiveServer(params.get(IsLiveParamName)?.toLowerCase() === "true");
    }
    const cardResult = buildCardIdentifierResultFromParameters(params);
    setCardIdentifierResult(cardResult);
  }, [search]);

  // Monitors the iframe for changes in height and sends the new height to the parent window
  useEffect(() => {
    if (!merchantSessionKey) return;

    const observer = new MutationObserver(() => {
      const newScrollHeight = window.document.body.scrollHeight;
      new CardIFrameEventSender().sendScrollHeightChanged(newScrollHeight);
    });

    const targetDiv = document.getElementById("sp-container");
    if (targetDiv) {
      observer.observe(targetDiv, { attributes: true, attributeFilter: ["style"] });
    }
  }, [merchantSessionKey, liveServer]);

  // Handles the call back to the parent window when we've submitted the card details
  useEffect(() => {
    if (cardIdentifierResult) {
      // Inform our parent that we've submitted the card details and have received a result from Opayo
      const browserInfo = getBrowserInfo();
      new CardIFrameEventSender().sendSubmitCardResult({ cardIdentifierResult, browserInfo });
    }
  }, [cardIdentifierResult]);

  const showCardFrame = merchantSessionKey && liveServer !== undefined && !cardIdentifierResult;
  return (
    <div id='sage-payment-frame'>
      {!showCardFrame && iFrameType === IFrameType.CardDetails && (
        <div className='d-flex justify-content-center' style={{ display: "flex" }}>
          <div style={{ position: "absolute", top: "10px" }}>
            <DraycirSpinner />
          </div>
        </div>
      )}

      {showCardFrame && (
        <form ref={formRef} id='sage-payment-form'>
          <div id='sp-container'>{}</div>
          <div id='submit-container'>
            <button id='opayo-sumbit-button' type='submit' />
          </div>
        </form>
      )}
    </div>
  );
};

export default OpayoPiCardFramePage;
