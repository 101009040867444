import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import HttpStatusCode from "../../common/httpStatusCode";
import getProductInfo from "../../common/productInfo";
import Error404Page from "./Error404Page";
import { imgError403401 } from "../../assets/images";

import "./ErrorPage.scss";
import ErrorContainer from "./ErrorContainer";

type ErrorParams = {
  code: string;
};

/*
 * Displays an error
 */
const ErrorPage = (): ReactElement => {
  const { code } = useParams<ErrorParams>();
  const { t } = useTranslation();
  const { productShortName: productName } = getProductInfo();

  let imageError = null;
  let errorMessage = null;
  let detailMessage = null;
  let actionMessage = null;
  let goHome = false;

  switch (code) {
    case HttpStatusCode.NotFound.toString():
      return <Error404Page />;

    case HttpStatusCode.Forbidden.toString():
      imageError = imgError403401;
      errorMessage = t("errors.httpError403Forbidden.errorMessage", {
        ProductName: productName,
      });
      actionMessage = t("errors.httpError403Forbidden.actionMessage");
      goHome = true;
      break;

    case HttpStatusCode.BadGateway.toString():
      errorMessage = t("errors.httpError502BadGateway.errorMessage", {
        ProductName: productName,
      });
      actionMessage = t("errors.httpError502BadGateway.actionMessage");
      goHome = true;
      break;

    case HttpStatusCode.ServiceUnavailable.toString():
      errorMessage = t("errors.httpError503ServiceUnavailable.errorMessage", {
        ProductName: productName,
      });
      actionMessage = t("errors.httpError503ServiceUnavailable.actionMessage");
      goHome = true;
      break;

    default:
      errorMessage = t("errors.defaultUnhandledError.errorMessage");
      detailMessage = t("errors.defaultUnhandledError.detailMessage");
      actionMessage = t("defaultUnhandledError.actionMessage");
      goHome = true;
      break;
  }

  return (
    <ErrorContainer
      errorMessage={errorMessage}
      detailMessage={detailMessage}
      actionMessage={actionMessage}
      imageError={imageError}
      goHome={goHome}
    />
  );
};
export default ErrorPage;
