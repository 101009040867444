import { ReactElement } from "react";
import { Container, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tCommon } from "../../i18n";
import DraycirSpinner from "../../components/spinners/DraycirSpinner";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";
import "./PaymentProcessingCard.scss";

interface PaymentProcessingCardProps {
  counter?: number;
  dontNavigate?: boolean;
  errorMessage?: string;
}

/*
 * Card to display when a payment is being processed
 */
const PaymentProcessingCard = ({ counter, dontNavigate, errorMessage }: PaymentProcessingCardProps): ReactElement => {
  const { t } = useTranslation(tCommon.ns);

  const message = `paymentProcessing.processingPaymentMessage${counter ? Math.floor((counter / 3) % 3) : 0}`;

  return (
    <Container>
      <HtmlTitle subTitle1={t("pageTitles.pageProcessingPayment")} />
      <Card className='payment-processing-card'>
        <Card.Body className='processing-body'>
          <DraycirSpinner />
          <div className='processing-message'>{t(message)}</div>
          {dontNavigate && <div className='navigation-message'>{t("paymentProcessing.dontNavigateMessage")}</div>}
          {errorMessage && <div className='error-message'>{t(errorMessage)}</div>}
        </Card.Body>
      </Card>
    </Container>
  );
};

PaymentProcessingCard.defaultProps = {
  counter: 0,
  dontNavigate: false,
  errorMessage: undefined,
};

export default PaymentProcessingCard;
