import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Lottie from "lottie-react";
import DCCard from "../../components/DCCard/DCCard";
import { tCommon } from "../../i18n";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";
import "./BankDebitSetupStatusPage.scss";
import SDCButton from "../../components/DCButton/DCButton";
import DCButtonVariant from "../../common/types/dcButtonVariant";
import animationSuccess from "../../assets/data/anim/check-mark-success.json";
import animationFail from "../../assets/data/anim/cross-fail.json";
import { GoCardlessProviderName } from "../../models/paymentProviders/goCardless/goCardlessProvider";

const keyPrefix = "directDebitSetup";

const ValidProviders = [GoCardlessProviderName.toLowerCase()];

export type BankDebitSetupStatus = "completed" | "failed";

type BankDebitSetupStatusPageParams = {
  providerName: string;
  status: BankDebitSetupStatus;
};

/*
 * Displays the payment information and options
 */
const BankDebitSetupStatusPage = (): ReactElement => {
  const { status, providerName } = useParams<BankDebitSetupStatusPageParams>();
  const { t: tHtmlTitle } = useTranslation(tCommon.ns);
  const { t } = useTranslation(tCommon.ns, { keyPrefix });

  const validProvider = ValidProviders.includes(providerName.toLowerCase());
  const successful = status === "completed" && validProvider;
  const htmlTitle = successful ? "pageTitles.pageSetupSuccessful" : "pageTitles.pageSetupFailed";
  const animation = successful ? animationSuccess : animationFail;

  const renderContent = (): ReactElement => {
    // Not an expected provider?
    if (!validProvider) {
      return (
        <>
          <div className='payment-title title-container'>
            <h4>{t("titleError")}</h4>
            <div className='graphic-container'>
              <Lottie animationData={animationFail} loop={false} />
            </div>
          </div>
          <div className='payment-header'>
            <p>{t("messagePaymentProviderError", { provider: providerName })}</p>
          </div>
        </>
      );
    }

    if (successful) {
      return (
        <>
          <div className='payment-title title-container'>
            <h4>{t("titleCompleted")}</h4>
            <div className='graphic-container'>
              <Lottie animationData={animation} loop={false} />
            </div>
          </div>
          <div className='payment-header'>
            <p>{t("messageCompleted")}</p>
            <p>
              {t("description1", {
                // We could accept a query param when we have more providers that do bank debit
                provider: providerName,
              })}
            </p>
            <p>{t("description2")}</p>
          </div>
        </>
      );
    }

    // Not successful
    return (
      <>
        <div className='payment-title title-container'>
          <h4>{t("titleExit")}</h4>
          <div className='graphic-container'>
            <Lottie animationData={animation} loop={false} />
          </div>
        </div>
        <div className='payment-header'>
          <p>{t("messageFailed", { provider: providerName })}</p>
        </div>
      </>
    );
  };

  return (
    <Container>
      <HtmlTitle subTitle1={tHtmlTitle(htmlTitle)} />
      <DCCard className='w-100 setup-status-page'>
        <div className='sample-card text-center'>{renderContent()}</div>
      </DCCard>
      <div className='mt-3 d-flex justify-content-center'>
        <SDCButton text={t("buttonClose")} variant={DCButtonVariant.Secondary} onClick={() => window.close()} />
      </div>
    </Container>
  );
};

export default BankDebitSetupStatusPage;
