/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import DCCard from "../../components/DCCard/DCCard";
import CurrencyItem from "../../components/misc/CurrencyItem";
import { getFullAddressFromCompanyAddress } from "../../helpers/customerHelper";
import { isPaymentFailedStatus, isPaymentPaidStatus, isPaymentProcessingStatus } from "../../helpers/paymentTransactionHelper";
import { tCommon } from "../../i18n";
import PaymentTransactionSummary from "../../models/paymentTransactions/paymentTransactionSummary";
import { getPaymentTransactionSummary } from "../../services/paymentProcess/paymentProcess.service";
import { getFullName } from "../../utils/customerFormatter";
import { toShortDateTimeFromString } from "../../utils/dateFormatter";
import LoadingPage from "../LoadingPage";
import PaymentProcessingPage from "./PaymentProcessingPage";
import { useSession } from "../../context/session/SessionContext";
import animationFail from "../../assets/data/anim/cross-fail.json";
import animationSuccess from "../../assets/data/anim/check-mark-success.json";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";
import { getPaymentRequest } from "../../services/paymentRequests/paymentRequests.service";
import { PaymentRequestSummary } from "../../models/paymentRequests/paymentRequestSummary";
import { RedirectType } from "../../models/paymentRequests/redirectType";
import {
  PaymentState,
  getRedirectOnSuccessOption,
  getRedirectOnFailedOption,
  getValidRedirectUrl,
  buildRedirectUrl,
} from "./Completed/redirectHelper";
import LocationProvider from "../../common/providers/locationProvider";
import PaymentPageButtons from "./Completed/PaymentPageButtons";
import "./PaymentCompletePage.scss";

const keyPrefix = "paymentComplete";

/*
 * Checks the payment status and redirects to the appropriate page if needed
 */
const checkAndBuildRequestRedirectUrl = (
  paymentTransaction: PaymentTransactionSummary,
  paymentRequest: PaymentRequestSummary
): string | undefined => {
  let state: PaymentState | undefined;

  if (!paymentTransaction || !paymentRequest) return undefined;

  if (isPaymentPaidStatus(paymentTransaction.status)) {
    if (getRedirectOnSuccessOption(paymentRequest) === RedirectType.Auto) {
      state = "success";
    }
  } else if (isPaymentFailedStatus(paymentTransaction.status)) {
    if (getRedirectOnFailedOption(paymentRequest) === RedirectType.Auto) {
      state = "failed";
    }
  }

  if (!state) return undefined;

  const url = getValidRedirectUrl(paymentRequest.redirectOptions);
  if (!url) return undefined;

  return buildRedirectUrl(url, state, paymentRequest.id, paymentTransaction.id);
};

/*
 * Displays the payment information and options
 */
const PaymentCompletePage = (): ReactElement => {
  const { t: tHtmlTitle } = useTranslation(tCommon.ns);
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const [paymentRequestSummary, setPaymentRequestSummary] = useState<PaymentRequestSummary>();
  const [paymentTransactionSummary, setPaymentTransactionSummary] = useState<PaymentTransactionSummary>();
  const { sessionToken, sessionService } = useSession();

  // Loads in the payment transaction and marks the session as complete
  useEffect(() => {
    const loadPaymentTransaction = async () => {
      const result = await getPaymentTransactionSummary();
      setPaymentTransactionSummary(result);
    };
    const loadPaymentRequest = async () => {
      const reset = await getPaymentRequest();
      setPaymentRequestSummary(reset);
    };
    if (sessionToken) {
      loadPaymentTransaction();
      loadPaymentRequest();
    }
  }, [sessionToken, sessionService]);

  if (!paymentTransactionSummary || !paymentRequestSummary) return <LoadingPage />;

  if (isPaymentProcessingStatus(paymentTransactionSummary.status)) {
    return <PaymentProcessingPage />;
  }

  // Performs an Auto redirect if option is set in RedirectOptions
  const url = checkAndBuildRequestRedirectUrl(paymentTransactionSummary, paymentRequestSummary);
  if (url) {
    const location = new LocationProvider();
    location.replace(url);
  }

  const successful = !isPaymentFailedStatus(paymentTransactionSummary.status);
  const title = successful ? "titlePaymentSuccessful" : "titlePaymentFailed";
  const htmlTitle = successful ? "pageTitles.pagePaymentCompleteSuccessful" : "pageTitles.pagePaymentCompleteFailed";
  const animation = successful ? animationSuccess : animationFail;

  const mdSize = 12;
  return (
    <Container>
      <HtmlTitle subTitle1={tHtmlTitle(htmlTitle)} />
      <DCCard className='w-100 payment-status-page'>
        <div className='sample-card'>
          <div className={`text-center payment-title ${successful ? "successful" : "failed"}`}>
            <div
              style={{
                width: "40px",
                marginTop: "0px",
                marginBottom: "0px",
                marginRight: "8px",
                zIndex: "0",
              }}
            >
              <Lottie animationData={animation} title={t("title")} loop={false} />
            </div>
            <div style={{ paddingTop: "4px" }}>{t(title)}</div>
          </div>
          <div className='payment-header'>
            <b className='name'>{paymentTransactionSummary.customerDetails.companyName}</b>
            <div className='address'>{getFullAddressFromCompanyAddress(paymentTransactionSummary.customerDetails)}</div>
          </div>
          <div className='payment-detail containter'>
            <Row>
              <Col lg={6} md={mdSize} className='detail-group'>
                <div className='label'>{t("labelPaymentReference")}</div>
                <div className='detail'>{paymentTransactionSummary.payThemReference}</div>
              </Col>
              <Col md={6} className='detail-group'>
                {paymentTransactionSummary.statusDateTime && (
                  <>
                    <div className='label'>{t(successful ? "labelPaymentDate" : "labelPaymentAttemptDate")}</div>
                    <div className='detail'>{toShortDateTimeFromString(paymentTransactionSummary.statusDateTime)}</div>
                  </>
                )}
              </Col>
              <Col md={mdSize} className='detail-group'>
                <div className='label'>{t("labelYourName")}</div>
                <div className='detail'>
                  {getFullName(
                    paymentTransactionSummary.customerDetails.firstNames,
                    paymentTransactionSummary.customerDetails.surname
                  )}
                </div>
              </Col>
              <Col md={mdSize} className='detail-group'>
                <div className='label'>{t("labelTransactions")}</div>
                {paymentTransactionSummary.transactions.map((transaction) => (
                  <div className='detail' key={transaction.id}>
                    {transaction.transactionReference} - {transaction.description}
                  </div>
                ))}
              </Col>
              <Col md={mdSize} className='detail-group'>
                <div className='label'>{t(successful ? "labelAmountPaid" : "labelAmountAttempted")}</div>
                <div className='detail amount'>
                  <CurrencyItem currency={paymentTransactionSummary.currency} value={paymentTransactionSummary.totalAmount} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </DCCard>
      <PaymentPageButtons
        paymentSuccessful={successful}
        paymentTransaction={paymentTransactionSummary}
        paymentRequest={paymentRequestSummary}
      />
    </Container>
  );
};

export default PaymentCompletePage;
