import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import DCButtonVariant from "../../../common/types/dcButtonVariant";
import SDCButton from "../../../components/DCButton/DCButton";
import { tCommon } from "../../../i18n";

const keyPrefix = "paymentComplete";

/*
 * Displays the print button
 */
const PrintButton = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  return (
    <SDCButton
      text={t("buttonPrint")}
      variant={DCButtonVariant.Secondary}
      className='print-hide mx-2'
      onClick={() => window.print()}
    />
  );
};

export default PrintButton;
