import { ReactElement, useEffect } from "react";
import { Container } from "react-bootstrap";
import { getPayThemProductSiteUrl } from "../../helpers/environmentVariableHelpers";
import DraycirSpinner from "../../components/spinners/DraycirSpinner";
import "./HomePage.scss";

const HomePage = (): ReactElement => {
  const productUrl = getPayThemProductSiteUrl();

  // Just redirect to the main product site
  useEffect(() => {
    window.location.replace(productUrl);
  });

  return (
    <Container>
      <div className='d-flex justify-content-center'>
        <div className='d-flex flex-column justify-content-center'>
          <div className='text-center mt-4'>
            <DraycirSpinner />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default HomePage;
