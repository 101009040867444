const TestScriptUrl = "https://sandbox.opayo.eu.elavon.com/api/v1/js/sagepay.js";
const LiveScriptUrl = "https://live.opayo.eu.elavon.com/api/v1/js/sagepay.js";

const InjectedScriptElementId = "payThemOpayoPiScripts";
const InjectedScriptAttributeName = "paythem-opayo-injected-script";

export interface SagePayCheckoutOptions {
  merchantSessionKey: string;
  reusableCardIdentifier?: string;
  frmValidator?: unknown;
  fieldFormatter?: unknown;
}

export interface SagePayCheckoutResult {
  form: () => void;
}

export type SagePayCheckout = (options: SagePayCheckoutOptions) => SagePayCheckoutResult;

declare global {
  interface Window {
    sagepayCheckout: SagePayCheckout;
  }
}

/*
 * Removes any existing Opayo script
 */
export const removeOpayoPiScript = () => {
  // Remove the element where we injected the checkout script
  const elements = document.getElementsByName(InjectedScriptAttributeName);
  while (elements.length > 0) {
    if (elements[0].parentNode) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const loadOpayoPiScript = (
  merchantSessionKey: string,
  testScript: boolean,
  onLoaded: (wnd: Window, merchantSessionKey: string) => void
) => {
  const script = document.createElement("script");
  script.src = testScript ? TestScriptUrl : LiveScriptUrl;
  script.async = true;
  script.onload = () => {
    onLoaded(window, merchantSessionKey);
  };
  script.setAttribute("name", InjectedScriptAttributeName);
  const parent = document.getElementById(InjectedScriptElementId);

  if (!parent) {
    throw new Error("Document head or body is required to inject script.");
  }

  // Remove any existing scripts
  removeOpayoPiScript();

  parent.appendChild(script);
};

/*
 * Builds a form to redirect to the 3D Secure page
 */
export const buildOpayoPi3dSecureRedirectForm = (
  doc: Document,
  redirectUrl: string,
  cReqData: string,
  threeDSSessionData: string
): HTMLFormElement => {
  // Create a form dynamically
  const form = doc.createElement("form");
  form.method = "post";
  form.action = redirectUrl;

  let hiddenField = doc.createElement("input");
  hiddenField.type = "hidden";
  hiddenField.name = "creq";
  hiddenField.value = cReqData;

  // Append the input to the form
  form.appendChild(hiddenField);

  hiddenField = doc.createElement("input");
  hiddenField.type = "hidden";
  hiddenField.name = "threeDSSessionData";
  hiddenField.value = threeDSSessionData;

  // Append the input to the form
  form.appendChild(hiddenField);

  // The form needs to be added to the document before submitting
  doc.body.appendChild(form);

  return form;
};
