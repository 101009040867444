import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LocationProvider from "../../common/providers/locationProvider";
import SessionService from "../../services/sessions/sessions.service";
import PaymentRequestPage from "../PaymentRequest/PaymentRequestPage";
import LoadingPaymentRequest from "../LoadingPage";
import { getDecodedSessionToken } from "../../utils/jwtUtils";
import GeneralErrorPage from "../../layout/Errors/GeneralErrorPage";
import { tCommon } from "../../i18n";
import { validateIsReady } from "../../services/configuration/configuration.service";
import { IsReadyResult } from "../../services/configuration/configurationModels";
import ConfigurationIncompletePage from "../Configuration/ConfigurationIncompletePage";

const locationProvider = new LocationProvider();
const sessionService = new SessionService(locationProvider);

type PaymentRequestPageParams = {
  requestLinkId: string;
};

/*
 * Entry point for the request link for a payment request
 */
const RequestLinkPage = (): ReactElement => {
  const { requestLinkId } = useParams<PaymentRequestPageParams>();
  const [initialising, setInitialising] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [isReadyResult, setIsReadyResult] = useState<IsReadyResult>();

  const { t } = useTranslation(tCommon.ns, { keyPrefix: "errors.generalErrors" });

  // Applies for a session token if none exists
  useEffect(() => {
    const controller = new AbortController();
    const initialiseSession = async () => {
      // Checks if we have a non-expired session token, if not lets get one
      const haveSessionToken = sessionService.haveNonExpiredSessionToken();

      // If we don't have a session token, then we need to apply for one
      if (!haveSessionToken && !initialising && !hasError) {
        // Mark as initialising so we only call this oncce
        setInitialising(true);
        try {
          const newSessionToken = await sessionService.register(requestLinkId);

          // We store the session token on the query string, by doing so we cause a page reload
          sessionService.storeSessionToken(newSessionToken);
        } catch (error) {
          setHasError(true);
          if (error instanceof Error) {
            setErrorMessage(error.message);
          } else throw error;
        } finally {
          setInitialising(false);
        }
      }
    };

    initialiseSession();
    return () => controller?.abort();
    // Not interested when 'initialising' changes as we only want to call this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, requestLinkId]);

  // Read in the token from the query string
  const token = sessionService.getSessionToken();

  // Validates the sites configuration
  const handleValidateSiteConfiguration = async () => {
    const result = await validateIsReady();
    setIsReadyResult(result);
  };

  // Make sure the request link url matches the one in the session token
  useEffect(() => {
    if (token && requestLinkId) {
      const payload = getDecodedSessionToken(token);
      if (payload.rlid !== requestLinkId) {
        setErrorMessage(t("requestLinkNotMatchingSession"));
      }
    }
  }, [token, requestLinkId, t]);

  // Validates the configuration status of the tenants dataset when we have a token
  useEffect(() => {
    if (token && token.length > 0) {
      handleValidateSiteConfiguration();
    }
  }, [token]);

  if (errorMessage) {
    return <GeneralErrorPage errorMessage={errorMessage} />;
  }

  // Make sure that the PayThem site configuration is complete, else show a message
  if (isReadyResult?.isReady === false) {
    return <ConfigurationIncompletePage isReadyResult={isReadyResult} onRefresh={() => handleValidateSiteConfiguration()} />;
  }

  // Show the request if everything is fine
  if (token && isReadyResult?.isReady === true) {
    return <PaymentRequestPage />;
  }

  // Show the loading page
  return <LoadingPaymentRequest />;
};

export default RequestLinkPage;
