import { CompanyAddress } from "../models/companySettings/CompanySettingsModels";

const DefaultNameSeparator = " ";
const DefaultAddressSeparator = ", ";

export const getFullName = (firstNames: string, surname: string): string => {
  let fullname = firstNames.trim() || "";
  const tempSurname = surname.trim();
  if (tempSurname) {
    fullname = `${fullname}${fullname ? DefaultNameSeparator : ""}${surname}`;
  }
  return fullname;
};

/*
 * Returns the full address from an array of strings in a single string
 */
export const getFullAddress = (address: (string | undefined)[], separator: string = DefaultAddressSeparator): string => {
  let fullAddress = "";
  address.forEach((line) => {
    if (line) {
      fullAddress = `${fullAddress}${fullAddress ? separator : ""}${line}`;
    }
  });
  return fullAddress;
};

/*
 * Returns the full address from an CompanyAddress in a single string
 */
export const getFullAddressFromCompanyAddress = (
  address: CompanyAddress,
  separator: string = DefaultAddressSeparator
): string => {
  const addressArray = [address.address1, address.address2, address.city, address.state, address.postCode, address.country];
  return getFullAddress(addressArray, separator);
};
