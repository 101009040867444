import Lottie from "lottie-react";
import { ReactElement } from "react";
import { Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";
import { tCommon } from "../../i18n";
import DCCard from "../../components/DCCard/DCCard";
import animationReserved from "../../assets/data/anim/pending-status.json";
import "./PaymentRequestReservePendingPage.scss";

const keyPrefix = "paymentRequest.reserved";

/*
 * Page for displaying when the payment request has a reserved payment pending
 * This is when funds have been reserved but not yet captured
 */
const PaymentRequestReservePendingPage = (): ReactElement => {
  const { t: tTitle } = useTranslation(tCommon.ns);
  const { t } = useTranslation(tCommon.ns, { keyPrefix });

  return (
    <Container>
      <HtmlTitle subTitle1={tTitle("pageTitles.pagePaymentReserved")} />
      <DCCard className='w-100 status-page'>
        <div className='sample-card'>
          <Col className='status-header'>
            <div className='animitation-container'>
              <Lottie animationData={animationReserved} title={t("title")} loop={false} />
            </div>
          </Col>
          <div className='status-description'>
            <div className='status-title mb-2'>{t("title")}</div>
            <div className='main-text'>{t("descriptionFundsReserved1")}</div>
            <div className='main-text'>{t("descriptionFundsReserved2")}</div>
            <div className='main-text'>{t("descriptionFundsReserved3")}</div>
          </div>
        </div>
      </DCCard>
    </Container>
  );
};

export default PaymentRequestReservePendingPage;
