import { getPayThemAdminSiteUrl } from "../helpers/environmentVariableHelpers";
import { removeForwardslash } from "../utils/urlUtils";

class AdminPortalRoutes {
  public static CompanyDetails = "/settings/details";

  public static PaymentProviders = "/settings/payment-providers";

  public static Customisation = "/settings/customisation";

  public static getUrl(): string {
    return removeForwardslash(getPayThemAdminSiteUrl());
  }

  public static buildUrl(path: string) {
    return `${AdminPortalRoutes.getUrl()}${path}`;
  }

  public static getCompanyDetailsSettingsUrl(): string {
    return `${AdminPortalRoutes.getUrl()}${AdminPortalRoutes.CompanyDetails}`;
  }

  public static getPaymentProvidersSettingsUrl(): string {
    return `${AdminPortalRoutes.getUrl()}${AdminPortalRoutes.PaymentProviders}`;
  }
}

export default AdminPortalRoutes;
