import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import HttpStatusCode from "../common/httpStatusCode";
import getProductInfo from "../common/productInfo";
import HomePage from "../views/Home/HomePage";
import PaymentRequestPage from "../views/PaymentRequest/PaymentRequestPage";
import RequestLinkPage from "../views/RequestLink/RequestLinkPage";
import TestPage from "../views/Test/TestPage";
import ErrorPage from "./Errors/ErrorPage";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import RouteConsts from "./RouteConsts";
import PaymentCompletePage from "../views/Payments/PaymentCompletePage";
import PaymentReservedPage from "../views/Payments/PaymentReservedPage";
import PaymentProcessingPage from "../views/Payments/PaymentProcessingPage";
import TrueLayerRedirectPage from "../views/PaymentProviders/TrueLayer/TrueLayerRedirectPage";
import { TrueLayerProviderName } from "../models/paymentProviders/trueLayer/trueLayerModels";
import SessionExpiredPage from "../views/SessionExpired/SessionExpiredPage";
import PaymentWaitingPage from "../views/Payments/PaymentWaitingPage";
import TotalProcessingRedirectPage from "../views/PaymentProviders/TotalProcessing/TotalProcessingRedirectPage";
import { TotalProcessingProviderName } from "../models/paymentProviders/totalProcessing/totalProcessingProvider";
import IntentToPayServiceProvider from "../context/intentToPay/IntentToPayProvider";
import OpayoPiCardFramePage from "../views/PaymentProviders/Opayo/Pi/IFrame/OpayoPiCardFramePage";
import OpayoPi3dSecureRedirectPage from "../views/PaymentProviders/Opayo/Pi/IFrame/OpayoPi3dSecureRedirectPage";
import { HealthPage } from "../views/HealthPage";
import OpayoPi3dSecureFramePage from "../views/PaymentProviders/Opayo/Pi/IFrame/OpayoPi3dSecureFramePage";
import "./Layout.scss";
import BankDebitSetupStatusPage from "../views/Payments/BankDebitSetupStatusPage";
import PaymentBankDebitScheduledPage from "../views/Payments/PaymentBankDebitSchedulePage";

const Layout = (): ReactElement => {
  const pi = getProductInfo();
  return (
    <IntentToPayServiceProvider>
      <BrowserRouter>
        <Header />
        <Container fluid>
          <div>{pi.appVersion}</div>
          <div className='flex-container'>
            <div className='d-flex flex-column my-4 main-page'>
              <Switch>
                <Route key='/health' path='/health' component={HealthPage} />
                <Route path={RouteConsts.Home} exact component={HomePage} />
                <Route path='/test' exact component={TestPage} />
                {/* <Route path='/styles' exact component={StyleGuidePage} /> */}
                <Route path={`${RouteConsts.RequestLink}/:requestLinkId`} component={RequestLinkPage} />
                <Route path='/session' component={PaymentRequestPage} />
                <Route
                  path={`${RouteConsts.PaymentProcessing}/${TrueLayerProviderName}/:token`}
                  component={TrueLayerRedirectPage}
                />
                <Route
                  path={`${RouteConsts.PaymentProcessing}/${TotalProcessingProviderName}`}
                  component={TotalProcessingRedirectPage}
                />
                <Route path={`${RouteConsts.PaymentProcessing}/:providerName`} component={PaymentProcessingPage} />
                <Route path={RouteConsts.PaymentProcessing} component={PaymentProcessingPage} />
                <Route path={RouteConsts.PaymentWaiting} component={PaymentWaitingPage} />
                <Route path={RouteConsts.PaymentReserved} component={PaymentReservedPage} />
                <Route path={RouteConsts.PaymentBankDebitScheduled} component={PaymentBankDebitScheduledPage} />
                <Route path={RouteConsts.PaymentComplete} component={PaymentCompletePage} />
                <Route path={RouteConsts.OpayoEmbeddedCard} component={OpayoPiCardFramePage} />
                <Route path={RouteConsts.OpayoEmbedded3dSecure} component={OpayoPi3dSecureFramePage} />
                <Route path={RouteConsts.Opayo3dSecureRedirect} component={OpayoPi3dSecureRedirectPage} />
                <Route path={RouteConsts.SessionExpired} component={SessionExpiredPage} />
                <Route
                  path={`${RouteConsts.DirectDebitSetupStatus}/:providerName/:status`}
                  component={BankDebitSetupStatusPage}
                />
                <Route path={`${RouteConsts.Error}/:code`} component={ErrorPage} />
                <Redirect to={`${RouteConsts.Error}/${HttpStatusCode.NotFound.toString()}`} />
              </Switch>
            </div>
          </div>
        </Container>
        <Footer />
      </BrowserRouter>
    </IntentToPayServiceProvider>
  );
};

export default Layout;
