import { ReactElement } from "react";
import DraycirSpinner from "../../../../../components/spinners/DraycirSpinner";
import CardIFrameEventSender from "./CardIFrameEventSender";

/*
 * iFrame component that informs the main window that it should redirect to the processing page
 * This is shown after the 3d secure challange has been completed. The URL for this page was
 * return from our main web api after a post from Opayo
 */
const OpayoPi3dSecureRedirectPage = (): ReactElement => {
  // Inform the main window that it needs to redirect to the processing page
  new CardIFrameEventSender().sendRedirectToProcessing();
  return (
    <div className='d-flex justify-content-center '>
      <DraycirSpinner />
    </div>
  );
};

export default OpayoPi3dSecureRedirectPage;
