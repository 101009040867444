import { DefaultBlankText } from "../common/commonConsts";

/*
 *
 */
export function getFormattedCurrencyNumber(currency: string, value: number): string {
  const style = { style: "currency", currency };

  const numFormat = new Intl.NumberFormat([], style);
  const formattedNumber = value.toLocaleString([], {
    minimumFractionDigits: numFormat.resolvedOptions().minimumFractionDigits,
    maximumFractionDigits: numFormat.resolvedOptions().maximumFractionDigits,
  });

  return formattedNumber;
}

/*
 * Returns currency with value
 */
export function toCurrencyString(currency: string, value: number): string {
  return getFormattedCurrencyNumber(currency, value);
}

/*
 * Returns currency with value
 */
export function toCurrencyStringWithoutSymbol(currency: string, value: number, includeSpace = true): string {
  const formattedNumber = getFormattedCurrencyNumber(currency, value);
  return includeSpace ? `${currency} ${formattedNumber}` : currency + formattedNumber;
}

/*
 * Returns the currency with value or given text if value is null
 */
export function currencyOrString(
  currency: string,
  value: number | undefined | null,
  blankText: string = DefaultBlankText
): string {
  if (!value) return blankText;
  return toCurrencyStringWithoutSymbol(currency, value);
}
