import Lottie from "lottie-react";
import { ReactElement, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import DCButtonVariant from "../../common/types/dcButtonVariant";
import SDCButton from "../../components/DCButton/DCButton";
import DCCard from "../../components/DCCard/DCCard";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";
import CurrencyItem from "../../components/misc/CurrencyItem";
import useCompanyInfo from "../../context/companyInfo/CompanyInfoContext";
import { useSession } from "../../context/session/SessionContext";
import { getFullAddressFromCompanyAddress } from "../../helpers/customerHelper";
import { tCommon } from "../../i18n";
import PaymentTransactionSummary from "../../models/paymentTransactions/paymentTransactionSummary";
import { getPaymentStatus, getPaymentTransactionSummary } from "../../services/paymentProcess/paymentProcess.service";
import { getFullName } from "../../utils/customerFormatter";
import { toShortDateTimeFromString } from "../../utils/dateFormatter";
import LoadingPage from "../LoadingPage";
import animation from "../../assets/data/draycir4-spinner-data.json";
import RouteConsts from "../../layout/RouteConsts";
import "./PaymentCompletePage.scss";
import { isPaymentJourneyStatusTypeTerminal } from "../../models/paymentProcess/paymentJourneyStatusType";

const keyPrefix = "paymentWaiting";

const TimerInterval = 60000;

/*
 * Shown when a payment is being processed and could take some time, for example a bank payment needs to be checked
 */
const PaymentWaitingPage = (): ReactElement => {
  const { t: tHtmlTitle } = useTranslation(tCommon.ns);
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const { sessionToken, sessionService } = useSession();
  const location = useLocation();
  const history = useHistory();
  const companyInfo = useCompanyInfo();

  const [summary, setSummary] = useState<PaymentTransactionSummary>();
  const [errorMessage, setErrorMessage] = useState("");

  // Loads in the payment transaction and marks the session as complete
  useEffect(() => {
    const getSummary = async () => {
      const result = await getPaymentTransactionSummary();
      setSummary(result);
    };
    if (sessionToken) {
      getSummary();
    }
  }, [sessionToken, sessionService]);

  // Called to check if the payment status has completed processing
  const checkPaymentStatus = async () => {
    try {
      const status = await getPaymentStatus();

      if (isPaymentJourneyStatusTypeTerminal(status.paymentJourneyStatusType)) {
        const url = `${RouteConsts.PaymentComplete}${location.search}`;
        history.push(url);
        return;
      }
    } catch {
      setErrorMessage("errorProblemCheckingStatus");
    }
  };

  // Set a timer to keep on checking the status
  useEffect(() => {
    const timer = setInterval(checkPaymentStatus, TimerInterval);
    return () => {
      clearInterval(timer);
    };
  });

  if (!summary || !companyInfo) return <LoadingPage />;

  return (
    <Container>
      <HtmlTitle subTitle1={tHtmlTitle("pageTitles.pagePaymentWaiting")} />
      <DCCard className='w-100 payment-status-page'>
        <div className='sample-card'>
          <div className='text-center payment-title waiting'>
            <div
              style={{
                width: "40px",
                marginTop: "0px",
                marginBottom: "0px",
                marginRight: "8px",
                zIndex: "0",
              }}
            >
              <Lottie animationData={animation} title={t("title")} />
            </div>
            <div style={{ paddingTop: "4px" }}>{t("title")}</div>
          </div>
          <div className='payment-header text-center'>
            <p>{t("description1")}</p>
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: t("description2") }} />
            <p>{t("description3")}</p>
            <p>{t("description4")}</p>
            {errorMessage && <p className='error-message'>{t(errorMessage)}</p>}
          </div>
          <div className='payment-header'>
            <b className='name'>{summary.customerDetails.companyName}</b>
            <div className='address'>{getFullAddressFromCompanyAddress(summary.customerDetails)}</div>
          </div>
          <div className='payment-detail containter'>
            <Row>
              <Col lg={6} md={12} className='detail-group'>
                <div className='label'>{t("labelPaymentReference")}</div>
                <div className='detail'>{summary.payThemReference}</div>
              </Col>
              <Col md={6} className='detail-group'>
                {summary.statusDateTime && (
                  <>
                    <div className='label'>{t("labelPaymentDate")}</div>
                    <div className='detail'>{toShortDateTimeFromString(summary.statusDateTime)}</div>
                  </>
                )}
              </Col>
              <Col md={12} className='detail-group'>
                <div className='label'>{t("labelYourName")}</div>
                <div className='detail'>{getFullName(summary.customerDetails.firstNames, summary.customerDetails.surname)}</div>
              </Col>
              <Col md={12} className='detail-group'>
                <div className='label'>{t("labelAmount")}</div>
                <div className='detail amount'>
                  <CurrencyItem currency={summary.currency} value={summary.totalAmount} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </DCCard>
      <div className='mt-3 d-flex justify-content-center print-hide-me'>
        <SDCButton
          text={t("buttonPrint")}
          variant={DCButtonVariant.Secondary}
          className='print-hide mx-2'
          onClick={() => window.print()}
        />{" "}
        <SDCButton
          text={t("buttonClose")}
          variant={DCButtonVariant.Secondary}
          className='print-hide mx-2'
          onClick={() => window.close()}
        />
      </div>
    </Container>
  );
};

export default PaymentWaitingPage;
