import { BrowserInfo } from "../../../../utils/BrowserInfo/browserInfo";

export const MerchantSessionKeyParamName = "merchantSessionKey";
export const IsLiveParamName = "live";
export const CardIdentifierParamName = "card-identifier";
export const CardIdentifierHttpCodeParamName = "card-identifier-http-code";
export const CardIdentifierErrorCodeParamName = "card-identifier-error-code";
export const CardIdentifierErrorMessageParamName = "card-identifier-error-message";

// Parameter names for 3D Secure
export const ThreeDSecureAcsRedirectUrlParamName = "acsRedirectUrl";
export const ThreeDSecureCRegDataParamName = "cRegData";
export const ThreeDSecureSessionDataParamName = "sessionData";

export interface CardIdentifierResult {
  cardIdentifier: string | null;
  cardIdentifierHttpCode: string | null;
  cardIdentifierErrorCode: string | null;
  cardIdentifierErrorMessage: string | null;
}

export interface SubmitResult {
  cardIdentifierResult: CardIdentifierResult;
  browserInfo: BrowserInfo;
}

export interface Redirect3dSecureData {
  acsRedirectUrl: string;
  cRegData: string;
  threeDSSessionData: string;
}

// The type of the iframe that we are loading
export enum IFrameType {
  CardDetails,
  ThreeDSecureRedirect,
}
