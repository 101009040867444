import Lottie from "lottie-react";
import { ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { tCommon } from "../../i18n";
import animationSessionExpired from "../../assets/data/anim/session-expired.json";
import { SessionExpiredRedirectParamName } from "./sessionExpiredPageConts";

/*
 * Displays the session expired page
 */
const SessionExpiredPage = (): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix: "errors.generalErrors" });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirect = query.get(SessionExpiredRedirectParamName);

  return (
    <Container fluid style={{ paddingTop: "0", marginTop: "0" }}>
      <Row>
        <Col className='p-0'>
          <div className='d-flex flex-column align-items-center' style={{ paddingTop: "0", marginTop: "0" }}>
            <h4 className='error-message mt-4'>{t("sessionExpired")}</h4>
            <p>{t("sessionExpiredDescription")}</p>
            <div>{redirect && <Link to={redirect}>{t("sessionExpiredTryAgain")}</Link>}</div>
            <div style={{ width: "55%", marginTop: "0px", marginBottom: "0px", zIndex: "0" }}>
              <Lottie animationData={animationSessionExpired} title={t("sessionExpired")} />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default SessionExpiredPage;
