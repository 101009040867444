/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import RouteConsts from "../../../layout/RouteConsts";
import {
  TotalProcessingCompletePayRequestProviderData,
  TotalProcessingCompletePayResultProviderData,
  initTotalProcessingCompletePayRequestProviderData,
} from "../../../models/paymentProviders/totalProcessing/totalProcessingProvider";
import { completePayProcess } from "../../../services/paymentProcess/paymentProcess.service";
import PaymentProcessingCard from "../../Payments/PaymentProcessingCard";
import { PaymentChargeType } from "../../../models/paymentProcess/paymentChargeType";
import { PaymentOptions } from "../../../models/paymentProcess/paymentOptions";
import { initialiseCompletePayRequest } from "../../../models/paymentProcess/completePayRequest";

const IdQueryParam = "id";
const ResourcePathQueryParam = "resourcePath";

/*
 * Total Processsing redirects to this page after card details are entered.
 * We then call the complete payment process API to get finalise the payment.
 */
const TotalProcessingRedirectPage = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const callCompletePayment = async (id: string, resourcePath: string) => {
      const paymentOptions = {
        paymentChargeType: PaymentChargeType.Manual,
      } as PaymentOptions;

      const providerData = initTotalProcessingCompletePayRequestProviderData(id, resourcePath);

      const result = await completePayProcess<
        TotalProcessingCompletePayRequestProviderData,
        TotalProcessingCompletePayResultProviderData
      >(initialiseCompletePayRequest(paymentOptions, providerData));

      // Redirect to the payment processing page which checks the status
      const url = `${RouteConsts.PaymentProcessing}?${queryParams.toString()}`;
      history.replace(url);
    };

    const id = queryParams.get(IdQueryParam);
    const resourcePath = queryParams.get(ResourcePathQueryParam);

    if (!id || !resourcePath) {
      throw Error();
    }

    callCompletePayment(id, resourcePath);
    // RRR we disable this because we want to run this once. A bug occurred when this did have a dependency on queryParams and histoy
    // and it would run twice. This could cause PayThem to mark the payment as failed when it was actually successful.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PaymentProcessingCard />;
};

export default TotalProcessingRedirectPage;
