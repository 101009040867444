import React, { FunctionComponent, ReactNode, SVGProps } from "react";
import DCButtonIconPosition from "../../common/types/dcButtonIconPosition";
import DCButtonSize from "../../common/types/dcButtonSize";
import DCButtonVariant from "../../common/types/dcButtonVariant";

type Props = {
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  type?: "submit" | "reset" | "button" | undefined;
  iconPosition?: DCButtonIconPosition;
  variant?: DCButtonVariant;
  size?: DCButtonSize;
  text?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  children?: ReactNode;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const SDCButton: React.FC<Props> = ({
  Icon,
  type,
  iconPosition,
  variant,
  size,
  text,
  onClick,
  className,
  children,
  ...props
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    onClick={onClick}
    className={`dc-button ${variant || ""} ${size || ""} ${className || ""}`}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {/* Button icon position Left */}
    {Icon !== undefined && iconPosition === DCButtonIconPosition.Left && <Icon />}
    {text !== undefined && <div className='label'>{text}</div>}
    {/* Button icon position Right */}
    {Icon !== undefined && iconPosition === DCButtonIconPosition.Right && <Icon />}
    {children}
  </button>
);

SDCButton.defaultProps = {
  type: "button",
  variant: DCButtonVariant.Default,
  Icon: undefined,
  iconPosition: DCButtonIconPosition.Left,
  size: DCButtonSize.Default,
  className: undefined,
  text: undefined,
  onClick: undefined,
  children: undefined,
};

export default SDCButton;
