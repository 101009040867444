import CompanySettingsApi from "../../common/api/payThemPayerApi/companySettingsApi";
import { IsReadyResult, ValidateConfigurationResult } from "./configurationModels";

/*
 * Validates the current configuration
 */
export const validateConfiguration = async (): Promise<ValidateConfigurationResult> => {
  const api = new CompanySettingsApi();
  const result = await api.validateConfiguration();
  return result;
};

/*
 * Valdiates the current configuraiton returning an IsReadyResult
 */
export const validateIsReady = async (): Promise<IsReadyResult> => {
  const api = new CompanySettingsApi();
  const result = await api.validateIsReady();
  return result;
};
