import { ReactElement } from "react";
import { RedirectType } from "../../../models/paymentRequests/redirectType";
import PaymentFailedButton from "./PaymentFailedButton";
import PaymentSuccessButton from "./PaymentSuccessButton";
import PrintButton from "./PrintButton";
import { getRedirectOnSuccessOption, getRedirectOnFailedOption } from "./redirectHelper";
import { PaymentRequestSummary } from "../../../models/paymentRequests/paymentRequestSummary";
import PaymentTransactionSummary from "../../../models/paymentTransactions/paymentTransactionSummary";
import "./PaymentPageButtons.scss";

interface PaymentPageButtonsProps {
  paymentSuccessful: boolean;
  paymentTransaction: PaymentTransactionSummary;
  paymentRequest: PaymentRequestSummary;
}

/*
 * Builds the payment page buttons
 */
const PaymentPageButtons = ({
  paymentSuccessful,
  paymentTransaction,
  paymentRequest,
}: PaymentPageButtonsProps): ReactElement => (
  <div className='print-hide-me'>
    <div className='mt-3 d-flex justify-content-center'>
      {((paymentSuccessful && getRedirectOnSuccessOption(paymentRequest) !== RedirectType.Auto) ||
        (!paymentSuccessful && getRedirectOnFailedOption(paymentRequest) !== RedirectType.Auto)) && <PrintButton />}
      {/* The payment was successful */}
      {paymentSuccessful && getRedirectOnSuccessOption(paymentRequest) !== RedirectType.Auto && (
        <PaymentSuccessButton paymentTransaction={paymentTransaction} redirectOptions={paymentRequest?.redirectOptions} />
      )}
      {/* The payment wasn't successul */}
      {!paymentSuccessful && getRedirectOnFailedOption(paymentRequest) !== RedirectType.Auto && (
        <PaymentFailedButton paymentTransaction={paymentTransaction} redirectOptions={paymentRequest?.redirectOptions} />
      )}
    </div>
  </div>
);

export default PaymentPageButtons;
