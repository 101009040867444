import { ReactElement, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSession } from "../../context/session/SessionContext";
import RouteConsts from "../../layout/RouteConsts";
import { TrueLayerProviderName } from "../../models/paymentProviders/trueLayer/trueLayerModels";
import { activateContinueProcessing, getPaymentStatus } from "../../services/paymentProcess/paymentProcess.service";
import sessionsConstants from "../../services/sessions/sessions.constants";
import PaymentProcessingCard from "./PaymentProcessingCard";
import {
  PaymentJourneyStatusType,
  isPaymentJourneyStatusTypeTerminal,
} from "../../models/paymentProcess/paymentJourneyStatusType";
import { PaymentMethodType } from "../../models/paymentProviders/paymentMethodType";

const MaximumStatusChecks = 10;
const DontNavigateChecks = 3;
const CheckPaymentStatusDelay = 2500;

const MethodParam = "method";

interface QueryParams {
  providerName: string;
  token: string;
}

/*
 * Display the payment processing page
 */
const PaymentProcessingPage = (): ReactElement => {
  const queryParams = useParams<QueryParams>();
  const history = useHistory();
  const location = useLocation();
  const { sessionToken } = useSession();
  const [callCounter, setCallCounter] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [dontNavigate, setDontNavigate] = useState(false);
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParams.providerName === TrueLayerProviderName.toLowerCase() && queryParams.token) {
      const parameters = new URLSearchParams(location.search);
      parameters.set(sessionsConstants.STORAGETOKENKEY, queryParams.token);
      history.push({ search: parameters.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.providerName, queryParams.token]);

  const createAndPushUrl = (path: string): void => {
    const parameters = new URLSearchParams();
    parameters.set(sessionsConstants.STORAGETOKENKEY, sessionToken);
    const url = `${path}?${parameters.toString()}`;
    history.push(url);
  };

  // Called to check if the payment status has completed processing
  const checkPaymentStatus = async () => {
    try {
      if (!dontNavigate && callCounter > DontNavigateChecks) {
        setDontNavigate(true);
      }

      // If we're in the bank debit flow, redirect to the bank debit scheduled page as this payment method takes a long time
      if (searchParams.get(MethodParam) === PaymentMethodType.BankDebit) {
        createAndPushUrl(RouteConsts.PaymentBankDebitScheduled);
      }

      // If we've checked the status too many times, redirect to the payment waiting page
      if (callCounter > MaximumStatusChecks) {
        activateContinueProcessing();
        createAndPushUrl(RouteConsts.PaymentWaiting);
        return;
      }

      const status = await getPaymentStatus();

      /// If we're in a terminal state, redirect to the payment complete page
      if (isPaymentJourneyStatusTypeTerminal(status.paymentJourneyStatusType)) {
        createAndPushUrl(RouteConsts.PaymentComplete);
        return;
      }

      // Are we waiting for the user to complete the payment? If so, redirect to the payment reserved page
      if (status.paymentJourneyStatusType === PaymentJourneyStatusType.Pending) {
        createAndPushUrl(RouteConsts.PaymentReserved);
        return;
      }
    } catch {
      setErrorMessage("paymentProcessing.errorProblemCheckingStatus");
    }
    setCallCounter((count) => count + 1);
  };

  useEffect(() => {
    const timer = setInterval(checkPaymentStatus, CheckPaymentStatusDelay);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return <PaymentProcessingCard counter={callCounter} errorMessage={errorMessage} dontNavigate={dontNavigate} />;
};

export default PaymentProcessingPage;
