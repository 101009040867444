import { PaymentRequestSummary } from "../../../models/paymentRequests/paymentRequestSummary";
import { RedirectOptions } from "../../../models/paymentRequests/redirectOptions";
import { RedirectType } from "../../../models/paymentRequests/redirectType";

const PaymentRequestIdParam = "payThemPRID";
const PamentTransactionIdParam = "payThemPTID";
const PaymentStateParam = "payThemPS";

export type PaymentState = "success" | "failed" | "processing";

/*
 * Builds url for redirecting to the url provided in the payment request
 */
export const buildRedirectUrl = (
  url: string,
  state: PaymentState,
  paymentRequestId: string,
  paymentTransactionId: string
): string => {
  const redirectUrl = new URL(url);
  redirectUrl.searchParams.append(PaymentStateParam, state);
  redirectUrl.searchParams.append(PaymentRequestIdParam, paymentRequestId);
  redirectUrl.searchParams.append(PamentTransactionIdParam, paymentTransactionId);

  return redirectUrl.toString();
};

/*
 * Returns the on sccuess RedirectType from the payment request
 */
export const getRedirectOnSuccessOption = (paymentRequest: PaymentRequestSummary): RedirectType => {
  if (paymentRequest && paymentRequest.redirectOptions && paymentRequest.redirectOptions.redirectUrl) {
    return paymentRequest.redirectOptions.successRedirectType;
  }
  return RedirectType.None;
};

/*
 * Returns the on failed RedirectType from the payment request
 */
export const getRedirectOnFailedOption = (paymentRequest: PaymentRequestSummary): RedirectType => {
  if (paymentRequest && paymentRequest.redirectOptions && paymentRequest.redirectOptions.redirectUrl) {
    return paymentRequest.redirectOptions.failureRedirectType;
  }
  return RedirectType.None;
};

/*
 * Returns a valid redirect url from the payment request, if not found throws an error
 */
export const getValidRedirectUrl = (redirectOptions?: RedirectOptions): string => {
  const url = redirectOptions?.redirectUrl;
  if (url && url.length > 0) {
    return url;
  }
  throw new Error("Redirect url is not valid");
};
