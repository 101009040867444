import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import DCButtonVariant from "../../../common/types/dcButtonVariant";
import SDCButton from "../../../components/DCButton/DCButton";
import { useSession } from "../../../context/session/SessionContext";
import { tCommon } from "../../../i18n";
import PaymentTransactionSummary from "../../../models/paymentTransactions/paymentTransactionSummary";
import { getRequestLinkPath } from "../../../utils/jwtUtils";
import { RedirectOptions } from "../../../models/paymentRequests/redirectOptions";
import { RedirectType } from "../../../models/paymentRequests/redirectType";
import { buildRedirectUrl, getValidRedirectUrl } from "./redirectHelper";
import LocationProvider from "../../../common/providers/locationProvider";

const keyPrefix = "paymentComplete";

const RETRY_PARAM = "pt";

interface PaymentFailedButtonProps {
  paymentTransaction: PaymentTransactionSummary;
  redirectOptions?: RedirectOptions;
}

/*
 * Displays the rety button for failed payments and handles the retry logic
 */
const PaymentFailedButton = ({ paymentTransaction, redirectOptions }: PaymentFailedButtonProps): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const [buttonLabel, setButtonLabel] = useState<string>(t("buttonRetry"));
  const [customUrl, setCustomUrl] = useState<string>();
  const { sessionToken } = useSession();
  const history = useHistory();

  // Build the button label and custom url if required
  useEffect(() => {
    if (redirectOptions && redirectOptions.failureRedirectType === RedirectType.Button) {
      const url = buildRedirectUrl(
        getValidRedirectUrl(redirectOptions),
        "failed",
        paymentTransaction.paymentRequestId,
        paymentTransaction.id
      );
      if (url) setCustomUrl(url);
      if (redirectOptions.redirectButtonTitle && redirectOptions.redirectButtonTitle.length > 0) {
        setButtonLabel(redirectOptions.redirectButtonTitle);
      }
    }
  }, [paymentTransaction, redirectOptions]);

  const handleRetry = () => {
    if (customUrl) {
      const location = new LocationProvider();
      location.replace(customUrl);
      return;
    }
    // TODO Here we want to reset the transaction cart and reload the customer details
    //    const decoded = getDecodedSessionToken(sessionToken);

    // We dont pass the in the current session token as we want to start a new session
    const url = `${getRequestLinkPath(sessionToken)}?${RETRY_PARAM}=${paymentTransaction.id}`;

    history.push(url);
  };

  return (
    <SDCButton text={buttonLabel} variant={DCButtonVariant.Primary} className='print-hide-me' onClick={() => handleRetry()} />
  );
};

PaymentFailedButton.defaultProps = {
  redirectOptions: undefined,
};

export default PaymentFailedButton;
