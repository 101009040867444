import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DCButtonVariant from "../../../common/types/dcButtonVariant";
import SDCButton from "../../../components/DCButton/DCButton";
import { tCommon } from "../../../i18n";

import { openInNewTab } from "../../../utils/httpUtilities";
import { getPayThemAdminSiteUrl } from "../../../helpers/environmentVariableHelpers";
import useCompanyInfo from "../../../context/companyInfo/CompanyInfoContext";
import { RedirectOptions } from "../../../models/paymentRequests/redirectOptions";
import PaymentTransactionSummary from "../../../models/paymentTransactions/paymentTransactionSummary";
import { RedirectType } from "../../../models/paymentRequests/redirectType";
import { buildRedirectUrl, getValidRedirectUrl } from "./redirectHelper";

const keyPrefix = "paymentComplete";

interface PaymentSuccessButtonProps {
  paymentTransaction: PaymentTransactionSummary;
  redirectOptions?: RedirectOptions;
}

/*
 * Displays the rety button for failed payments and handles the retry logic
 */
const PaymentSuccessButton = ({ paymentTransaction, redirectOptions }: PaymentSuccessButtonProps): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const [buttonLabel, setButtonLabel] = useState<string>(t("buttonWebsite"));
  const [customUrl, setCustomUrl] = useState<string>();
  const companyInfo = useCompanyInfo();

  // Build the button label and custom url if required
  useEffect(() => {
    if (redirectOptions && redirectOptions.successRedirectType === RedirectType.Button) {
      const url = buildRedirectUrl(
        getValidRedirectUrl(redirectOptions),
        "success",
        paymentTransaction.paymentRequestId,
        paymentTransaction.id
      );
      setCustomUrl(url);
      if (redirectOptions.redirectButtonTitle && redirectOptions.redirectButtonTitle.length > 0) {
        setButtonLabel(redirectOptions.redirectButtonTitle);
      }
    } else {
      // Use the url
      setCustomUrl(companyInfo?.website ?? getPayThemAdminSiteUrl());
    }
  }, [paymentTransaction, redirectOptions, companyInfo]);

  if (!companyInfo || !customUrl) {
    return <></>;
  }

  const handleClicked = () => {
    openInNewTab(customUrl);
  };

  return <SDCButton text={buttonLabel} variant={DCButtonVariant.Primary} className='print-hide-me' onClick={handleClicked} />;
};

PaymentSuccessButton.defaultProps = {
  redirectOptions: undefined,
};

export default PaymentSuccessButton;
