/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";

interface ErrorContainerProps {
  errorMessage?: string | null;
  detailMessage?: string | null;
  actionMessage?: string | null;
  imageError?: string | null;
  goHome: boolean;
}

const ErrorContainer = ({
  errorMessage,
  detailMessage,
  actionMessage,
  imageError,
  goHome,
}: ErrorContainerProps): ReactElement => (
  <Container fluid>
    <Row>
      <Col className='p-0'>
        <div className='d-flex flex-column align-items-center error-container page-container'>
          {imageError ? <img alt='' src={imageError} /> : null}
          {errorMessage ? <span className='error-message'>{errorMessage}</span> : null}
          {detailMessage ? <span className='detail-message'>{detailMessage}</span> : null}
          {/* {goHome
            ? // <Link className='go-home' to='/'>
              { actionMessage }
            : // </Link>
              null} */}
        </div>
      </Col>
    </Row>
  </Container>
);

ErrorContainer.defaultProps = {
  errorMessage: undefined,
  detailMessage: undefined,
  actionMessage: undefined,
  imageError: undefined,
};

export default ErrorContainer;
