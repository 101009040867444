import RouteConsts from "../../../layout/RouteConsts";
import { UrlUtils } from "../../../utils/urlUtils";
import { CompletePayTypeRequest, initialiseCompletePayRequest } from "../../paymentProcess/completePayRequest";
import IntentToPayRequestProviderData from "../../paymentProcess/intentToPayRequestProviderData";
import IntentToPayResultProviderData from "../../paymentProcess/intentToPayResultProviderData";
import { PaymentChargeType } from "../../paymentProcess/paymentChargeType";
import { PaymentOptions } from "../../paymentProcess/paymentOptions";

export const GoCardlessProviderName = "GoCardless";

export enum GoCardlessIntegrationType {
  // Setting up a new mandate
  MandateSetup = "MandateSetup",

  // Bank debit. Known as one-off payment
  DirectDebit = "DirectDebit",

  // Faster Payment using Instant Bank Pay
  InstantBankPay = "InstantBankPay",

  // First payment with Instant Bank Pay with a new mandate setup
  FirstPaymentAndMandateSetup = "FirstPaymentAndMandateSetup",
}

export interface GoCardlessIntentToPayRequestProviderData extends IntentToPayRequestProviderData {
  integrationType: GoCardlessIntegrationType;
  redirectUrl?: string;
  exitUrl?: string;
}
export interface GoCardlessIntentToPayResultProviderData extends IntentToPayResultProviderData {
  // True if this is a sandbox payment
  isSandbox: boolean;
}
export interface GoCardlessCompletePayRequestProviderData extends IntentToPayRequestProviderData {
  integrationType: GoCardlessIntegrationType;
  providerToken?: string;
}
export interface GoCardlessCompletePayResultProviderData extends IntentToPayResultProviderData {}

/**
 * Gets a redirect URL after flow completion
 * @returns absolute redirect URL
 */
export const getRedirectUrl = () =>
  UrlUtils.buildPathFromDomain(`${RouteConsts.DirectDebitSetupStatus}/${GoCardlessProviderName}/completed`);

/**
 * Gets an exit URL after flow error
 * @returns absolute exit URL
 */
export const getExitUrl = () =>
  UrlUtils.buildPathFromDomain(`${RouteConsts.DirectDebitSetupStatus}/${GoCardlessProviderName}/failed`);

/*
 * Constructor for GoCardlessntentOfPaymentRequestProviderData
 */
export const initGoCardlessIntentToPayRequestProviderData = (
  integrationType: GoCardlessIntegrationType
): GoCardlessIntentToPayRequestProviderData => {
  const providerData: GoCardlessIntentToPayRequestProviderData = {
    providerName: GoCardlessProviderName,
    integrationType,
    redirectUrl: getRedirectUrl(),
    exitUrl: getExitUrl(),
  };
  return providerData;
};

export const initBankDebitGoCardlessIntentToPayRequestProviderData = (
  paymentMethodId: string
): CompletePayTypeRequest<GoCardlessIntentToPayRequestProviderData> => {
  const paymentOptions: PaymentOptions = {
    paymentChargeType: PaymentChargeType.Token,
    storedPaymentMethodId: paymentMethodId,
  };
  const providerData: GoCardlessIntentToPayRequestProviderData = {
    providerName: GoCardlessProviderName,
    integrationType: GoCardlessIntegrationType.DirectDebit,
  };
  return initialiseCompletePayRequest(paymentOptions, providerData);
};

export const initMandateSetupGoCardlessCompletePayRequestProviderData = (
  mandateId: string
): CompletePayTypeRequest<GoCardlessCompletePayRequestProviderData> => {
  const paymentOptions: PaymentOptions = {};
  const providerData: GoCardlessCompletePayRequestProviderData = {
    providerName: GoCardlessProviderName,
    integrationType: GoCardlessIntegrationType.MandateSetup,
    providerToken: mandateId,
  };
  return initialiseCompletePayRequest(paymentOptions, providerData);
};

export const initInstantBankPayGoCardlessCompletePayRequestProviderData = (
  paymentId: string
): CompletePayTypeRequest<GoCardlessCompletePayRequestProviderData> => {
  const paymentOptions: PaymentOptions = {};
  const providerData: GoCardlessCompletePayRequestProviderData = {
    providerName: GoCardlessProviderName,
    integrationType: GoCardlessIntegrationType.InstantBankPay,
    providerToken: paymentId,
  };
  return initialiseCompletePayRequest(paymentOptions, providerData);
};
