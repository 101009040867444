import BasePayThemPayerApi from "./basePayThemPayerApi";
import { PaymentProviderConfigurationDetails } from "../../../models/paymentProviders/paymentProviderConfigurationDetails";

class PaymentProvidersApi extends BasePayThemPayerApi {
  private endPoint = "payment-providers";

  /*
   * Returns the payment provider configurations associated with this payment request
   */
  public async getPaymentProviderConfigurations(amountToPay: number): Promise<PaymentProviderConfigurationDetails[]> {
    const params = new URLSearchParams();
    params.append("amountToPay", amountToPay.toString());
    const result = await this.get<PaymentProviderConfigurationDetails[]>(this.endPoint, params);
    return result;
  }
}

export default PaymentProvidersApi;
