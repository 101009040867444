import { ReactElement, ReactNode, useState } from "react";
import { PaymentRequestSummary } from "../../models/paymentRequests/paymentRequestSummary";
import { PaymentRequestContext } from "./PaymentRequestContext";

interface PaymentRequestProviderProps {
  paymentRequestSummary: PaymentRequestSummary;
  children: ReactNode;
}
const PaymentRequestProvider = ({ paymentRequestSummary, children }: PaymentRequestProviderProps): ReactElement => {
  const [paymentRequest] = useState<PaymentRequestSummary>(paymentRequestSummary);
  return <PaymentRequestContext.Provider value={paymentRequest}>{children}</PaymentRequestContext.Provider>;
};

export default PaymentRequestProvider;
