/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosResponse } from "axios";
import { ReactElement } from "react";
import ErrorCode from "../../common/errorCode";
import { ProblemDetails } from "../../common/problemDetails";
import HttpStatusCode from "../../common/httpStatusCode";
import LocationProvider from "../../common/providers/locationProvider";
import { handledErrorCodes } from "../../utils/errorUtils";
import ErrorContainer from "../../layout/Errors/ErrorContainer";
import FetchResponseException from "../../infrastructure/exceptions/FetchResponseException";
import GeneralErrorPage from "../../layout/Errors/GeneralErrorPage";
// import { useAuth } from "../../context/auth/AuthContext";

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

const ErrorFallback = ({ error }: ErrorFallbackProps): ReactElement => {
  //  const { authService } = useAuth();
  const locationProvider = new LocationProvider();

  if (error instanceof FetchResponseException) {
    return <GeneralErrorPage errorMessage={error.message} />;
  }

  console.log(error);
  console.log(error.stack);
  console.log(error.cause);

  const redirectToLoginPage = (): ReactElement => {
    const redirectingMessage = "TO REMOVE: Redireting";
    // const redirectUrl = authService.authorize();
    // locationProvider.replace(redirectUrl);
    return <div>{redirectingMessage}</div>;
  };

  const redirectToErrorPage = (status: string): ReactElement => {
    const redirectingMessage = `TO REMOVE: Redireting ${status}`;
    // locationProvider.replace(`/error/${status}`);
    return <div>{redirectingMessage}</div>;
  };

  let handledResult: ReactElement | null = null;
  if (axios.isAxiosError(error)) {
    const response = error.response as AxiosResponse<ProblemDetails>;
    if (!response) {
      return redirectToErrorPage(ErrorCode.Unknown.toString());
    }

    switch (response.status) {
      case HttpStatusCode.Unauthorized:
        handledResult = redirectToLoginPage();
        break;

      case HttpStatusCode.NotFound:
      case HttpStatusCode.BadGateway:
      case HttpStatusCode.ServiceUnavailable:
        handledResult = redirectToErrorPage(response.status.toString());
        break;

      case HttpStatusCode.Forbidden:
        if (handledErrorCodes.includes(response.data.errorCode)) {
          handledResult = redirectToErrorPage(response.data.errorCode.toString());
        } else {
          handledResult = redirectToErrorPage(response.status.toString());
        }
        break;

      default:
        handledResult = redirectToErrorPage(ErrorCode.Unknown.toString());
        break;
    }
  }
  return handledResult ?? <GeneralErrorPage errorMessage='We encountered an error while processing your request.' />;

  redirectToErrorPage(ErrorCode.Unknown.toString());
};

export default ErrorFallback;
