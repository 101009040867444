import { ReactElement } from "react";
import { getFormattedCurrencyNumber } from "../../utils/currencyFormatter";
import { DefaultBlankText } from "../../common/commonConsts";
import "./CurrencyItem.scss";

interface CurrencyItemProps {
  currency: string;
  value: number | undefined;
  blankText?: string;
  currencyClass?: string;
  valueClass?: string;
}

/*
 * Formats and displays a currency and value
 */
const CurrencyItem = ({ currency, value, blankText, currencyClass, valueClass }: CurrencyItemProps): ReactElement => {
  if (value === undefined) return <div>{blankText}</div>;
  return (
    <div className='currency-item'>
      <span className={`currency-iso me-2${currencyClass ? ` ${currencyClass}` : ""}`}>{currency}</span>
      <span className={`currency-value${valueClass ? ` ${valueClass}` : ""}`}>
        {getFormattedCurrencyNumber(currency, value)}
      </span>
    </div>
  );
};

CurrencyItem.defaultProps = {
  blankText: DefaultBlankText,
  currencyClass: undefined,
  valueClass: undefined,
};

export default CurrencyItem;
