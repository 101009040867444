import { ReactElement, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSession } from "../../../context/session/SessionContext";
import RouteConsts from "../../../layout/RouteConsts";
import sessionsConstants from "../../../services/sessions/sessions.constants";
import { getRequestLinkPath } from "../../../utils/jwtUtils";
import PaymentProcessingCard from "../../Payments/PaymentProcessingCard";

// Error query parameter
const ErrorQueryParam = "error";

// Error value returned when cancel pressed on the TrueLayer dialog
const ErrorDialogCancellation = "tl_dialog_cancel";

interface RouteParams {
  token: string;
}

/*
 * Deals with the redirect from TrueLayer
 * http://...paythem.co/payment-processing/truelayer/<SESSION-TOKEN>?payment_id={GUID}
 * where payment_id is the identifer provided by TrueLayer
 */
const TrueLayerRedirectPage = (): ReactElement => {
  const routeParams = useParams<RouteParams>();
  const history = useHistory();
  const location = useLocation();
  const { sessionToken, setTokenOnUrl } = useSession();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get(ErrorQueryParam);

  // The redirect URL we passed to TrueLayer can't contain query parameters so we stored the session id
  // in the route path. We extract this out and set it to be a query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const { token } = routeParams;
    searchParams.set(sessionsConstants.STORAGETOKENKEY, token);

    // Place the token as a query item only if we currently dont have one
    if (token && sessionToken !== token) {
      setTokenOnUrl(routeParams.token);
    }

    // If the user pressed cancel, just redirect to the original PR
    if (error === ErrorDialogCancellation) {
      const url = getRequestLinkPath(token);
      history.push(url);
      return;
    }

    // Redirect to the payment processing page which checks the status
    const url = `${RouteConsts.PaymentProcessing}?${searchParams.toString()}`;
    history.replace(url);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PaymentProcessingCard />;
};

export default TrueLayerRedirectPage;
