export interface BrowserInfo {
  javaScriptEnabled?: boolean;
  javaEnabled?: boolean;
  language?: string;
  browserName?: string;
  browserVersion?: string;
  colorDepth?: number;
  screenHeight?: number;
  screenWidth?: number;
  timeZoneOffsetInMinutes?: number;
  challengeWindowSize?: string;
}

/*
 * The navigator.javaEnabled has been deprecated so we need to check if it exists before calling it.
 */
const javaEnabled = (): boolean => {
  if (typeof navigator !== "undefined" && typeof navigator.javaEnabled === "function") {
    return navigator.javaEnabled();
  }
  return false;
};

/*
 * Gets information on the browser
 */
export const getBrowserInfo = (): BrowserInfo => ({
  javaScriptEnabled: true,
  javaEnabled: javaEnabled(),
  language: navigator.language,
  timeZoneOffsetInMinutes: new Date().getTimezoneOffset(),
  screenHeight: window.screen.height,
  screenWidth: window.screen.width,
  colorDepth: window.screen.colorDepth,
});
