import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { Container, Col } from "react-bootstrap";
import { tCommon } from "../../i18n";
import DCCard from "../../components/DCCard/DCCard";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";
import animation from "../../assets/data/anim/pending-or-processing.json";

import "./PaymentRequestCurrentlyProcessingPage.scss";

const keyPrefix = "paymentRequest.processingOrPending";

/*
 * Displayed when the payment request has payment transactions that are currently processing or pending
 * We dont want to accept another payment until the current one has completed
 */
const PaymentRequestCurrentlyProcessingPage = (): ReactElement => {
  const { t: tTitle } = useTranslation(tCommon.ns);
  const { t } = useTranslation(tCommon.ns, { keyPrefix });

  return (
    <Container>
      <HtmlTitle subTitle1={tTitle("pageTitles.pagePaymentProcessingOrPending")} />
      <DCCard className='w-100 status-page'>
        <div className='sample-card'>
          <Col className='status-header'>
            <div className='animitation-container'>
              <Lottie animationData={animation} title={t("title")} loop />
            </div>
          </Col>
          <div className='status-description'>
            <div className='status-title mb-2'>{t("title")}</div>
            <div className='main-text'>{t("description1")}</div>
            <div className='main-text'>{t("description2")}</div>
            <div className='main-text'>{t("description3")}</div>
          </div>
        </div>
      </DCCard>
    </Container>
  );
};

export default PaymentRequestCurrentlyProcessingPage;
