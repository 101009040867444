import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";

/*
 * Page shown when the payment request type is not supported
 */
const UnsupportedPaymentType = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <Container>
      <HtmlTitle subTitle1={t("pageTitles.pageTitleUnsupported")} />
      <div style={{ textAlign: "center" }}>
        <h1>{t("This is an unsupported payment request type")}</h1>
        <p>
          {t(
            "The payment request type is not supported by this application. Please contact the sender of the payment request."
          )}
        </p>
      </div>
    </Container>
  );
};

export default UnsupportedPaymentType;
