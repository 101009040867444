export const CardEventMessageNameValue = "opayo-card-event-message";
export type CardEventMessageName = typeof CardEventMessageNameValue;

export const CardPressPaymentButtonType = "press-payment-button";
export const CardSubmitResultType = "submit-card-result";
export const CardScriptLoaded = "card-script-loaded";
export const CardScrollHeightChanged = "scroll-height-changed";
export const CardRedirectTo3dSecure = "redirect-to-3d-secure";
export const CardRedirectToProcessing = "redirect-to-processing";
export const CardIFrameType = "card-iframe-type";

export type CardEventMessageType =
  | typeof CardPressPaymentButtonType
  | typeof CardSubmitResultType
  | typeof CardScriptLoaded
  | typeof CardScrollHeightChanged
  | typeof CardRedirectTo3dSecure
  | typeof CardRedirectToProcessing
  | typeof CardIFrameType;

export interface CardEventMessage<T> {
  name: CardEventMessageName;
  type: CardEventMessageType;
  data?: T;
}
