export enum PaymentJourneyStatusType {
  Unknown = "Unknown",
  Success = "Success",
  Failed = "Failed",
  Processing = "Processing",
  Pending = "Pending",
}

export const PaymentJourneyStatusTypeMap = new Map<PaymentJourneyStatusType, string>([
  [PaymentJourneyStatusType.Unknown, "Unknown"],
  [PaymentJourneyStatusType.Success, "Success"],
  [PaymentJourneyStatusType.Failed, "Failed"],
  [PaymentJourneyStatusType.Processing, "Processing"],
  [PaymentJourneyStatusType.Pending, "Pending"],
]);

const terminalStatuses: PaymentJourneyStatusType[] = [PaymentJourneyStatusType.Success, PaymentJourneyStatusType.Failed];

/*
 * True if the status is in a terminal state
 */
export const isPaymentJourneyStatusTypeTerminal = (status: PaymentJourneyStatusType): boolean =>
  terminalStatuses.includes(status);
