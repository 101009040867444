import Lottie from "lottie-react";
import { ReactElement, useState } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { tCommon } from "../../i18n";
import animation from "../../assets/data/anim/configuration-error.json";
import { IsReadyAction, IsReadyResult } from "../../services/configuration/configurationModels";
import DCCard from "../../components/DCCard/DCCard";
import AdminPortalLink from "../../components/adminPortal/AdminPortalLink";
import DCButton from "../../components/DCButton/DCButton";
import DCButtonSize from "../../common/types/dcButtonSize";
import DCButtonVariant from "../../common/types/dcButtonVariant";
import "./ConfigurationIncompletePage.scss";

interface ConfigurationIncompletePageProps {
  isReadyResult: IsReadyResult;
  onRefresh: () => Promise<void>;
}

/*
 * Shows incomplete configuration and help to complete it
 */
const ConfigurationIncompletePage = ({ isReadyResult, onRefresh }: ConfigurationIncompletePageProps): ReactElement => {
  const { t } = useTranslation(tCommon.ns, { keyPrefix: "configurationValidation" });
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const refresh = () => {
    setRefreshing(true);
    onRefresh().finally(() => setRefreshing(false));
  };

  const createItem = (action: IsReadyAction, optional: boolean): ReactElement => (
    <ListGroup.Item key={action.name} as='li' variant={optional ? "info" : "danger"}>
      {action.defaultMessage}{" "}
      {action.url && (
        <a href={action.url} target='_blank' rel='noreferrer'>
          {t("clickHere")}
        </a>
      )}
    </ListGroup.Item>
  );

  return (
    <Container>
      <h4 className='text-center'>{t("title")}</h4>
      <div className='configuration-issues'>
        <div className='d-flex justify-content-center'>
          <div style={{ width: "20%", marginTop: "0px", marginBottom: "0px", zIndex: "0" }}>
            <Lottie animationData={animation} title={t("sessionExpired")} />
          </div>
          <>
            <DCCard className='w-100'>
              <div className='issues-list-title'>{t("issuesTitle")}</div>
              <div className='issues-list-description'>
                Before you can start accepting payments you will need to complete the configuration of PayThem. You can do this
                by logging into the <AdminPortalLink>PayThem Admin Portal</AdminPortalLink> and actioning the items below.
              </div>
              <ListGroup as='ol' numbered>
                {isReadyResult.mandatoryActions && isReadyResult.mandatoryActions.map((action) => createItem(action, false))}
                {isReadyResult.optionalActions && isReadyResult.optionalActions.map((action) => createItem(action, true))}
              </ListGroup>
              <div className='mt-3'>
                <DCButton
                  disabled={refreshing}
                  variant={refreshing ? DCButtonVariant.Outlined : DCButtonVariant.Primary}
                  size={DCButtonSize.Default}
                  onClick={refresh}
                >
                  {t("buttonRefresh")}
                </DCButton>
              </div>
            </DCCard>
          </>
        </div>
      </div>
    </Container>
  );
};
export default ConfigurationIncompletePage;
