import { CardEventMessage, CardEventMessageNameValue, CardIFrameType, CardPressPaymentButtonType } from "../cardEvent";
import { IFrameType } from "../opayoPiCard";

export interface CardFrameCallback {
  onPaymentButtonSumbit: () => void;
  onSetIFrameType(type: IFrameType): void;
}

/*
 * This class is used to listen to events on the card iframe from the card parent (main PayThem window)
 */
class CardFrameEventListener {
  private callback: CardFrameCallback;

  constructor(callback: CardFrameCallback) {
    this.handleMessage = this.handleMessage.bind(this);
    window.addEventListener("message", this.handleMessage, false);
    this.callback = callback;
  }

  // Handles the incoming messages
  private handleMessage = (event: MessageEvent<CardEventMessage<unknown>>) => {
    if (!event.isTrusted) return;
    if (event.origin !== window.location.origin) return;
    if (event.data?.name !== CardEventMessageNameValue) return;

    if (event.data.type === CardPressPaymentButtonType) {
      this.callback.onPaymentButtonSumbit();
    }

    if (event.data.type === CardIFrameType) {
      this.callback.onSetIFrameType(event.data.data as IFrameType);
    }
  };

  public cleanup() {
    window.removeEventListener("message", this.handleMessage, false);
  }
}

export default CardFrameEventListener;
