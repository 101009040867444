import { ReactElement, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FeatureFlags, FlagsProvider } from "flagged";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { getHtmlPageTitle } from "./helpers/titleHelpers";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
import Layout from "./layout/Layout";
import SessionProvider from "./components/session/SessionProvider";
import SessionService from "./services/sessions/sessions.service";
import LocationProvider from "./common/providers/locationProvider";
import { initialiseDateFormatter } from "./utils/dateFormatter";
import TransactionCartProvider from "./context/transactionCart/TransactionCartProvider";
import CustomerDetailsProvider from "./context/customerDetails/CustomerDetailsProvider";
import CompanyInfoProvider from "./context/companyInfo/CompanyInfoProvider";
import { getFeatureFlagsSettingsAsync } from "./helpers/featureFlagHelper";
import StripeKeysProvider from "./context/stripeKeys/StripeKeysProvider";
import AppInsightsProvider from "./components/AppInsights/AppInsightsProvider";
import { reactPlugin } from "./appInsights";
import "./App.scss";
import LoadingPage from "./views/LoadingPage";

const locationProvider = new LocationProvider();
const sessionService = new SessionService(locationProvider);
const queryClient = new QueryClient();

// Load in the local locale for date/time formats
initialiseDateFormatter();

document.documentElement.className = "ptm";

/*
 * The main entry point for the application.
 */
const App = (): ReactElement => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();

  // Retrieve feature flags from the API
  useEffect(() => {
    getFeatureFlagsSettingsAsync().then((flags) => {
      setFeatureFlags(flags);
    });
  }, []);

  if (!featureFlags) {
    return <LoadingPage/>;
  }

  return (
    <AppInsightsProvider reactPlugin={reactPlugin}>
      <HelmetProvider>
        <Helmet>
          <title>{getHtmlPageTitle()}</title>
        </Helmet>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <FlagsProvider features={featureFlags}>
            <SessionProvider sessionService={sessionService}>
              <QueryClientProvider client={queryClient}>
                <CompanyInfoProvider>
                  <CustomerDetailsProvider>
                    <TransactionCartProvider>
                      <StripeKeysProvider>
                        <Layout />
                      </StripeKeysProvider>
                    </TransactionCartProvider>
                  </CustomerDetailsProvider>
                </CompanyInfoProvider>
              </QueryClientProvider>
            </SessionProvider>
          </FlagsProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </AppInsightsProvider>
  );
};
export default App;
