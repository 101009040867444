import { PaymentTransactionStatus } from "../models/paymentTransactions/paymentTransactionEnums";

const processingStatuses: PaymentTransactionStatus[] = [PaymentTransactionStatus.Processing];

const pendingStatuses: PaymentTransactionStatus[] = [PaymentTransactionStatus.Pending];

const paidStatuses: PaymentTransactionStatus[] = [PaymentTransactionStatus.Paid];

const failedStatuses: PaymentTransactionStatus[] = [
  PaymentTransactionStatus.Failed,
  PaymentTransactionStatus.NotAuthorised,
  PaymentTransactionStatus.Rejected,
  PaymentTransactionStatus.Refunded,
  PaymentTransactionStatus.Disputed,
  PaymentTransactionStatus.Cancelled,
  PaymentTransactionStatus.Duplicated,
];

export const isPaymentPendingStatus = (status: PaymentTransactionStatus): boolean => pendingStatuses.includes(status);

export const isPaymentProcessingStatus = (status: PaymentTransactionStatus): boolean => processingStatuses.includes(status);

export const isPaymentPaidStatus = (status: PaymentTransactionStatus): boolean => paidStatuses.includes(status);

export const isPaymentFailedStatus = (status: PaymentTransactionStatus): boolean => failedStatuses.includes(status);
