class RouteConsts {
  public static readonly Home = "/";

  public static readonly RequestLink = "/pr";

  public static readonly Error = "/error";

  public static readonly PaymentProcessing = "/payment-processing";

  public static readonly PaymentWaiting = "/payment-waiting-to-complete";

  public static readonly PaymentReserved = "/payment-reserved";

  public static readonly PaymentComplete = "/payment-complete";

  public static readonly PaymentScheduled = "/payment-scheduled";

  public static readonly PaymentBankDebitScheduled = "/payment-bankdebit-scheduled";

  public static readonly OpayoEmbeddedCard = "/opayo/card-payment-frame";

  public static readonly OpayoEmbedded3dSecure = "/opayo/3d-secure-frame";

  public static readonly Opayo3dSecureRedirect = "/opayo/3d-secure-redirect";

  public static readonly StripePaymentRedirect = "/payment-processing/stripe";

  public static readonly SessionExpired = "/session-expired";

  public static readonly DirectDebitSetupStatus = "/direct-debit-setup";
}

export default RouteConsts;
