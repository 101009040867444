import CompletePayRequestProviderData from "./completePayRequestProviderData";
import { PaymentChargeType } from "./paymentChargeType";
import { PaymentOptions } from "./paymentOptions";

export interface CompletePayRequest {
  // Manual or token payment, default to manual if tokens are not supported
  paymentChargeType: PaymentChargeType;

  // The optional stored payment method to use for collecting payment
  storedPaymentMethodId?: string;

  // Optionally store the payment details for future use when creating a payment intent
  storePaymentMethod?: boolean;

  // Optional data specific to the payment provider being used
  providerData?: CompletePayRequestProviderData;
}

export interface CompletePayTypeRequest<TProviderData extends CompletePayRequestProviderData> extends CompletePayRequest {
  // Optional data specific to the payment provider being used
  providerData?: TProviderData;
}

/*
 * Initialise a complete pay request from the payment options and provider data
 */
export const initialiseCompletePayRequest = <TProviderData extends CompletePayRequestProviderData>(
  paymentOptions?: PaymentOptions,
  providerData?: TProviderData
): CompletePayTypeRequest<TProviderData> => {
  const data: CompletePayTypeRequest<TProviderData> = {
    paymentChargeType: paymentOptions?.paymentChargeType ?? PaymentChargeType.Manual,
    storedPaymentMethodId: paymentOptions?.storedPaymentMethodId,
    storePaymentMethod: paymentOptions?.storePaymentMethod,
    providerData,
  };
  return data;
};
