/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import { tCommon } from "../../i18n";
import HtmlTitle from "../../components/HtmlTitle/HtmlTitle";
import DCCard from "../../components/DCCard/DCCard";
import animationSuccess from "../../assets/data/anim/check-mark-success.json";
import { useSession } from "../../context/session/SessionContext";
import { PaymentRequestSummary } from "../../models/paymentRequests/paymentRequestSummary";
import PaymentTransactionSummary from "../../models/paymentTransactions/paymentTransactionSummary";
import { getPaymentTransactionSummary } from "../../services/paymentProcess/paymentProcess.service";
import { getPaymentRequest } from "../../services/paymentRequests/paymentRequests.service";
import LoadingPage from "../LoadingPage";
import { getFullAddressFromCompanyAddress } from "../../helpers/customerHelper";
import { getFullName } from "../../utils/customerFormatter";
import { toShortDateTimeFromString } from "../../utils/dateFormatter";
import CurrencyItem from "../../components/misc/CurrencyItem";
import PaymentPageButtons from "./Completed/PaymentPageButtons";
import "./PaymentCompletePage.scss";

const keyPrefix = "paymentReserved";

const PaymentReservedPage = (): ReactElement => {
  const { t: tHtmlTitle } = useTranslation(tCommon.ns);
  const { t } = useTranslation(tCommon.ns, { keyPrefix });
  const [paymentRequestSummary, setPaymentRequestSummary] = useState<PaymentRequestSummary>();
  const [paymentTransactionSummary, setPaymentTransactionSummary] = useState<PaymentTransactionSummary>();
  const { sessionToken, sessionService } = useSession();

  // Loads in the payment transaction and marks the session as complete
  useEffect(() => {
    const loadPaymentTransaction = async () => {
      const result = await getPaymentTransactionSummary();
      setPaymentTransactionSummary(result);
    };
    const loadPaymentRequest = async () => {
      const reset = await getPaymentRequest();
      setPaymentRequestSummary(reset);
    };
    if (sessionToken) {
      loadPaymentTransaction();
      loadPaymentRequest();
    }
  }, [sessionToken, sessionService]);

  if (!paymentTransactionSummary || !paymentRequestSummary) return <LoadingPage />;

  return (
    <Container>
      <HtmlTitle subTitle1={tHtmlTitle("pageTitles.pagePaymentReserved")} />
      <DCCard className='w-100 payment-status-page'>
        <div className='sample-card'>
          <div className='text-center payment-title successful'>
            <div
              style={{
                width: "40px",
                marginTop: "0px",
                marginBottom: "0px",
                marginRight: "8px",
                zIndex: "0",
              }}
            >
              <Lottie animationData={animationSuccess} title={t("title")} loop={false} />
            </div>
            <div className='main-text'>{t("title")}</div>
          </div>
          <div className='payment-description'>
            <div className=''>{t("descriptionFundsReserved1")}</div>
            <div className=''>{t("descriptionFundsReserved2")}</div>
          </div>
          <div className='payment-header'>
            <b className='name'>{paymentTransactionSummary.customerDetails.companyName}</b>
            <div className='address'>{getFullAddressFromCompanyAddress(paymentTransactionSummary.customerDetails)}</div>
          </div>
          <div className='payment-detail containter'>
            <Row>
              <Col lg={6} md={12} className='detail-group'>
                <div className='label'>{t("labelPaymentReference")}</div>
                <div className='detail'>{paymentTransactionSummary.payThemReference}</div>
              </Col>
              <Col md={6} className='detail-group'>
                {paymentTransactionSummary.statusDateTime && (
                  <>
                    <div className='label'>{t("labelReservedDate")}</div>
                    <div className='detail'>{toShortDateTimeFromString(paymentTransactionSummary.statusDateTime)}</div>
                  </>
                )}
              </Col>
              <Col md={12} className='detail-group'>
                <div className='label'>{t("labelYourName")}</div>
                <div className='detail'>
                  {getFullName(
                    paymentTransactionSummary.customerDetails.firstNames,
                    paymentTransactionSummary.customerDetails.surname
                  )}
                </div>
              </Col>
              <Col md={12} className='detail-group'>
                <div className='label'>{t("labelAmountReserved")}</div>
                <div className='detail amount'>
                  <CurrencyItem currency={paymentTransactionSummary.currency} value={paymentTransactionSummary.totalAmount} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </DCCard>
      <PaymentPageButtons
        paymentSuccessful
        paymentTransaction={paymentTransactionSummary}
        paymentRequest={paymentRequestSummary}
      />
    </Container>
  );
};

export default PaymentReservedPage;
